import { IDownload } from "@/@types/api/bills";

import { http } from "@/plugins/axios";

const endPoint = '/Bills'

/**
 * https://wiki.sm-center.ru:1085/doku.php?id=api_core#%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D0%BB%D0%B5%D1%80_bills_-_%D0%BA%D0%B2%D0%B8%D1%82%D0%B0%D0%BD%D1%86%D0%B8%D0%B8
 * @param {IDownload} payload 
 * @returns {Promise} Blob
 */
const download = (payload: IDownload): Promise<Blob> => {
  return http.get(`${endPoint}/Download/${payload.id}`, {
    responseType: 'blob'
  })
}

export default {
  download
}