import moment from 'moment';
import { cloneDeep } from 'lodash';

import type { ICommonResult, ICommonResultExtended } from '@/@types/api/common';
import type {
  ISaveMeterValuePayload,
  ISetMeterCustomNamePayload,
  IMeterInfo,
  IMeterValueInfo,
} from '@/@types/api/meters';
import {
  TRANSFER_STATUS_KEY,
  VERIFICATION_STATUS_KEY,
  TRANSFERED_STATUS_KEY,
  CLOSED_STATUS_KEY,
  BLOCKED_STATUS_KEY,
  DISABLED_STATUS_KEY,
  BASE_METER_STATE,
} from '@/@types/api/meters';
import { http } from '@/plugins/axios';

const BASE_ENDPOINT = 'Meters';

function getEndpoint(path = ''): string {
  return `/${BASE_ENDPOINT}/${path}`;
}

/**
 * Сохраняет значение показаний прибора учета.
 *
 * @param {ISaveMeterValuePayload} payload
 * @returns {Promise<ICommonResultExtended>}
 */
const saveMeterValue = (
  payload: ISaveMeterValuePayload,
): Promise<ICommonResultExtended> => {
  return http.post(getEndpoint('SaveMeterValue'), payload);
};

/**
 * Удаляет значение показаний прибора учета.
 *
 * @param {number} meterId
 * @returns {Promise<ICommonResult>}
 */
const deleteMeterValue = (meterId: number): Promise<ICommonResult> => {
  return http.post(getEndpoint('DeleteMeterValue'), { meterId });
};

/**
 * Смена произвольного имени прибора.
 *
 * @param {ISetMeterCustomName} payload
 * @returns
 */
const setMeterCustomName = (
  payload: ISetMeterCustomNamePayload,
): Promise<ICommonResult> => {
  return http.post(getEndpoint('SetMeterCustomName'), payload);
};

/**
 * Возвращает список ПУ пользователя с тремя последними показаниями ПУ
 *
 * @returns {Promise<IMeterInfo[]>}
 */
const list = (): Promise<IMeterInfo[]> => {
  return http.get(getEndpoint('List')).then((response: any) =>
    response.Data.map((meter: IMeterInfo) => {
      if (!meter.Values.length) meter.Values.push(cloneDeep(BASE_METER_STATE));

      if (!meter.Values[0].Value && meter.StartValue) {
        meter.Values[0].Value = meter.StartValue;
      }

      if (!meter.Values[0].ValueT2 && meter.StartValueT2) {
        meter.Values[0].ValueT2 = meter.StartValueT2;
      }

      if (!meter.Values[0].ValueT3 && meter.StartValueT3) {
        meter.Values[0].ValueT3 = meter.StartValueT3;
      }

      if (meter.IsDisabled) {
        meter.statusCode = DISABLED_STATUS_KEY;
        return meter;
      }

      const nextCheckupDate = moment(
        meter.NextCheckupDate,
        'DD.MM.YYYY',
      ).startOf('day');
      const now = moment().startOf('day');
      const monthsDiff = nextCheckupDate.diff(now, 'months');
      const isCheckup =
        monthsDiff === 0
          ? nextCheckupDate.day() - now.day() > 0
          : monthsDiff < 0;
      const isCheckupOver = monthsDiff < 0;

      if (
        isCheckup &&
        meter.AllowMeterVerificationRequest &&
        !meter.ExistVerificationRequest &&
        monthsDiff <= 3 &&
        !isCheckupOver
      ) {
        meter.statusCode = VERIFICATION_STATUS_KEY;
        return meter;
      }

      if (isCheckupOver) {
        meter.statusCode = BLOCKED_STATUS_KEY;
        return meter;
      }

      const isTransmitted = meter.Values[0]?.Kind === 'Передано';

      if (isTransmitted || meter.AutoValueGettingOnly) {
        meter.statusCode = TRANSFERED_STATUS_KEY;
        return meter;
      }

      if (meter.ValuesCanAdd) {
        meter.statusCode = TRANSFER_STATUS_KEY;
        return meter;
      }

      meter.statusCode = CLOSED_STATUS_KEY;
      return meter;
    }),
  );
};

/**
 * Получение истории показаний по прибору
 *
 * @param {number} meterUniqueNum
 * @returns {Promise<IMeterValueInfo>}
 */
const meterValues = (meterUniqueNum: string): Promise<IMeterValueInfo> => {
  return http.get(getEndpoint('MeterValues'), {
    params: { meterUniqueNum },
  });
};

/**
 * Метод создания поверки по прибору
 *
 * @param {number} meterId
 * @returns {Promise<any>}
 * TODO: В документации не описан ответ
 */

// NOTE: Иначе запрос не работает
const createRequestOnMeterRecheck = (meterId: number): Promise<any> => {
  return http.post(getEndpoint(`CreateRequestOnMeterRecheck?meterID=${meterId}`));
};

export default {
  saveMeterValue,
  deleteMeterValue,
  setMeterCustomName,
  list,
  meterValues,
  createRequestOnMeterRecheck,
};
