import { createI18n } from 'vue-i18n'
import messages from '@/plugins/i18n/messages'

export default createI18n({
  legacy: false,
  locale: localStorage.getItem('language') || 'ru',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: messages,
  pluralRules: {
    /**
     * https://vue-i18n.intlify.dev/guide/essentials/pluralization#custom-pluralization
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    ru: function (choice: number) {
      if (choice === 0) {
        return 0;
      }

      if (choice === 1) {
        return 1;
      }

      if (choice > 1 && choice < 5) {
        return 2
      }
    
      if (choice >= 5) {
        return 3
      }
    }
  }
})