import { computed } from 'vue';
import { useStore } from 'vuex';
import cssColorVariables from '@/assets/cssColorVariables';

export function useThemeColors() {
  const store = useStore();

  const appTheme = computed((): string => (
    store.getters['config/getAppTheme']
  ));

  const baseColors = computed((): Record<PropertyKey, string | null> => (
    (cssColorVariables as any)[appTheme.value]
  ));

  const customColors = computed((): Record<PropertyKey, string | null> | null => (
    store.getters['config/getColors']
  ));

  return computed(() => ({
    ...baseColors.value,
    ...customColors.value
  }));
}