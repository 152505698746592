import { getCookie, setCookie, deleteCookie } from ".";

export default {
  install: (app: any) => {
    const cookiePlugin = {
      getCookie,
      setCookie,
      deleteCookie,
    };

    app.config.globalProperties.$cookie = cookiePlugin;

    app.provide("cookie", cookiePlugin);
  },
};
