/**
 * Делает первую букву строки заглавной
 * 
 * @param    {string | null | undefined}  string  строка
 * 
 * @returns  {string}                             строка с заглавной первой буквой
 * 
 * @example
 * // Пример 1: Заглавная первая буква для непустой строки
 * const result1 = capitalizeFirstLetter("example");
 * console.log(result1); // Вывод: "Example"
 *
 * // Пример 2: Обработка null, возвращает пустую строку
 * const result2 = capitalizeFirstLetter(null);
 * console.log(result2); // Вывод: ""
 *
 * // Пример 3: Обработка пустой строки, возвращает пустую строку
 * const result3 = capitalizeFirstLetter("");
 * console.log(result3); // Вывод: ""
 */

export const  capitalizeFirstLetter = (string: string | null | undefined): string => {
  // Проверка на null или пустую строку
  if (!string) {
    return string || '';
  }

  // Сделать первую букву заглавной и добавьте остальную часть строки
  const firstLetter = string.charAt(0).toUpperCase();
  const restOfString = string.slice(1);

  return firstLetter + restOfString;
}
