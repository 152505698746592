import {
  IDeleteAccountByIdent,
  IUpdateProfile,
  IGetGuestMobileUsers,
  IAddGuestMobileUser,
  IDeleteGuestMobileUser,
  IDeleteMobileUser,
  IAddAccountByIdent,
  IGetAccountPrefixes,
  IGetClientBases,
  IChangePassword,
} from '@/@types/api/user';
import { http } from '@/plugins/axios';

const endPoint = '/user';

// FIXME: Метод не задокументирован в доке?
const userInfo = () => {
  return http.get(`${endPoint}/info`);
}

const deleteAccountByIdent: IDeleteAccountByIdent = (payload) => {
  return http.post(`${endPoint}/deleteAccountByIdent`, payload);
};
const updateProfile: IUpdateProfile = (payload) => {
  return http.post(`${endPoint}/updateProfile`, payload);
};

const getGuestMobileUsers: IGetGuestMobileUsers = () => {
  return http.get(`${endPoint}/guestMobileUsers`);
};

const addGuestMobileUser: IAddGuestMobileUser = (payload) => {
  return http.post(`${endPoint}/addGuestMobileUser`, payload);
};

const deleteGuestMobileUser: IDeleteGuestMobileUser = (payload) => {
  return http.post(`${endPoint}/deleteGuestMobileUser`, payload);
};

const deleteMobileUser: IDeleteMobileUser = () => {
  return http.post(`${endPoint}/deleteMobileUser`);
};

const changePassword: IChangePassword = (payload) => {
  return http.post(`${endPoint}/changePassword`, payload);
};

/**
 * Привязка лицевого счета к аккаунту
 * @param {IAddAccountByIdentPayload} Параметры:
 * @param {string} ident Обязательный параметр - номер лицевого счета
 * @returns {<AxiosResponse<IAddAccountByIdentResult>>} Промис с данными пользователя
 *
 */
const addAccountByIdent: IAddAccountByIdent = (payload) => {
  return http.post(`${endPoint}/addAccountByIdent`, payload);
};

/**
 * Получение списка организаций и используемых префиксов лицевых счетов
 *
 * @callback IGetAccountPrefixes Получение списка организаций и используемых префиксов лицевых счетов.Список не пуст в том случае, если в БД настроены алиасы для разных организаций, но обращение идет не через алиас, а через основную базу.
 * @returns {<AxiosResponse<IAccountPrefixesResult[]>>} Промис со списком организаций и используемых префиксов лицевых счетов
 *
 */
const getAccountPrefixes: IGetAccountPrefixes = () => {
  return http.get(`${endPoint}/accountPrefixes`);
};

/**
 * Получение списка внешних баз клиента
 *
 * @callback IGetClientBases Метод получения списка внешних баз клиента. (Актуально для Ростова).
 * @returns {<AxiosResponse<INamedValue>>} Промис со списком баз
 *
 */
const getClientBases: IGetClientBases = () => {
  return http.get(`${endPoint}/getClientBases`);
};

export default {
  deleteAccountByIdent,
  updateProfile,
  getGuestMobileUsers,
  addGuestMobileUser,
  deleteGuestMobileUser,
  deleteMobileUser,
  addAccountByIdent,
  getAccountPrefixes,
  getClientBases,
  changePassword,
  userInfo,
};
