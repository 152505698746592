export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "add_personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить лицевой счет"])},
        "adding_a_personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление лицевого счета"])},
        "personal_account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер лицевого счета"])},
        "add_ident_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
        "add_ident_management_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляющая организация"])},
        "add_ident_bill_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма в любой из квитанций"])},
        "add_ident_bill_sum_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 3 месяца"])},
        "access_code_from_bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код доступа из квитанции"])},
        "code_from_bill_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код c последней квитанции"])},
        "six_digits_code_from_bill_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите первые шесть цифр кода доступа из квитанции"])},
        "adding_a_personal_account_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер лицевого счета расположен на квитанции, которую ежемесячно присылает вам управляющая компания"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
        "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицевой счет"])},
        "successfully_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["успешно подключен"])},
        "personal_account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицевой счет не найден"])},
        "personal_account_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицевой счет уже привязан к аккаунту"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
      },
      "en": {
        "add_personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add personal account"])},
        "adding_a_personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a personal account"])},
        "personal_account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal account number"])},
        "add_ident_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "add_ident_management_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management company"])},
        "add_ident_bill_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount from any bill"])},
        "add_ident_bill_sum_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the last 3 months"])},
        "access_code_from_bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access code from bill"])},
        "code_from_bill_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code from your last bill"])},
        "six_digits_code_from_bill_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the first six digits of the access code from your bill"])},
        "adding_a_personal_account_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal account number is located on the receipt that the management company sends you monthly"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal account"])},
        "successfully_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully added"])},
        "personal_account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal account not found"])},
        "personal_account_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal account is already added to the account"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
      }
    }
  })
}
