import { createStore } from 'vuex';
import config from '@/store/modules/config';
import auth from '@/store/modules/auth';
import requests from '@/store/modules/requests';
import techSupport from '@/store/modules/techSupport';
import accounting from '@/store/modules/accounting';
import meters from '@/store/modules/meters';
import bills from './modules/bills';
import common from '@/store/modules/common';
import user from '@/store/modules/user';
import createPersistedState from 'vuex-persistedstate';
import profile from '@/store/modules/profile';
import payOnline from '@/store/modules/payOnline';
import emergencyWorks from '@/store/modules/emergencyWorks';

export default createStore({
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    config: {
      namespaced: true,
      ...config,
    },
    requests: {
      namespaced: true,
      ...requests,
    },
    techSupport: {
      namespaced: true,
      ...techSupport,
    },
    profile: {
      namespaced: true,
      ...profile,
    },
    accounting: {
      namespaced: true,
      ...accounting,
    },
    meters: {
      namespaced: true,
      ...meters,
    },
    bills: {
      namespaced: true,
      ...bills,
    },
    common: {
      namespaced: true,
      ...common,
    },
    user: {
      namespaced: true,
      ...user,
    },
    auth: {
      namespaced: true,
      ...auth,
    },
    payOnline: {
      namespaced: true,
      ...payOnline,
    },
    emergencyWorks: {
      namespaced: true,
      ...emergencyWorks,
    }
  },
  plugins: [
    createPersistedState({
      paths: ['account'],
    }),
  ],
});
