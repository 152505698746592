/**
 * Форматирует число в соответствии с указанной локалью
 *
 * @param    {number}  number            Число, которое требуется отформатировать
 * @param    {string}  [locale='ru-RU']  Локаль, используемая для форматирования. По умолчанию 'ru-RU'
 * 
 * @returns  {string}                    Отформатированное число в виде строки
 *
 * @example
 * // Пример 1: Форматирование числа с локалью 'ru-RU' по умолчанию
 * const result1 = formatNumberToLocale(1234567.89);
 * console.log(result1); // Вывод: '1 234 567,89'
 *
 * // Пример 2: Форматирование числа с пользовательской локалью 'en-US'
 * const result2 = formatNumberToLocale(1234567.89, 'en-US');
 * console.log(result2); // Вывод: '1,234,567.89'
 */
export const formatNumberToLocale = (
  number: number,
  locale = "ru-RU"
): string => new Intl.NumberFormat(locale).format(number);
