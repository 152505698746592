import { IAppSetting, IMenuItem, IAppLink } from '@/@types/config';
// import configApi from '@/api/config';
import { useStore } from 'vuex';

export function useConfig() {
  const $store = useStore();

  const getAppSettings = (): IAppSetting => {
    return $store.getters['config/getAppSettings'];
  };

  const getMainMenu = (): IMenuItem[] => {
    return $store.getters['config/getMenu'];
  };

  const getAppsLinks = (): IAppLink[] => {
    return $store.getters['config/getAppsLinks'];
  };

  const getStartView = (): string => {
    return $store.getters['config/getStartView'];
  };

	const getLanguage = (): string => {
    return $store.getters['config/getLanguage'];
  };

  return {
    getAppSettings,
    getMainMenu,
    getAppsLinks,
    getStartView,
		getLanguage
  };
}
