import {
  IRegisterNewDevice,
  IGetMessages,
  IAddMessage,
  IRateAnswer,
  IAddFile,
  IGetFile,
  IGetSupportHelpTree
} from '@/@types/api/techSupport';
import { http as axios } from '@/plugins/axios';

const endPoint = "TechSupport";

const registerNewDevice: IRegisterNewDevice = async () => {
  return await axios.get(`/${endPoint}/RegisterNewDevice`);
};

const getMessages: IGetMessages = async (payload) => {
  return await axios.get(`/${endPoint}/GetMessages`, {
    params: payload,
  });
};

const addMessage: IAddMessage = async (payload) => {
  return await axios.post(`/${endPoint}/AddMessage`, payload);
};

const rateAnswer: IRateAnswer = async (payload) => {
  return await axios.post(`/${endPoint}/RateAnswer`, payload);
};

const addFile: IAddFile = async (payload) => {
  return await axios.postForm(`/${endPoint}/AddFile`, payload);
};

const getFile: IGetFile = async (payload) => {
  return await axios.get(`/${endPoint}/File`, {
    params: payload,
    responseType: "blob",
  });
};

const getSupportHelpTree: IGetSupportHelpTree = async () => {
  return axios.get(`/${endPoint}/GetSupportHelpTree`);
};

export default {
  registerNewDevice,
  getMessages,
  addMessage,
  rateAnswer,
  addFile,
  getFile,
  getSupportHelpTree,
};
