import { IMeterInfo, IMeterValueInfo } from '@/@types/api/meters';
import metersApi from '@/api/meters';
import { useStore } from 'vuex';

export function useMeters() {
  const $store = useStore();

  // FETCH DATA ----------------------------------------------------------------

  const fetchList = (): Promise<IMeterInfo[]> => {
    return metersApi.list().then((response) => {
      $store.dispatch('meters/setMeters', response);

      return response;
    });
  };

  const fetchMeterValues = async (
    meterUniqueNum: string,
  ): Promise<IMeterValueInfo> => {
    return await metersApi.meterValues(meterUniqueNum);
  };

  return {
    fetchList,
    fetchMeterValues,
  };
}
