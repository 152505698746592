export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "apps_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение для iOS и Android"])},
        "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работает на технологиях"])},
        "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ООО «Научно-исследовательский центр «Системы управления»"])}
      },
      "en": {
        "apps_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application for iOS and Android"])},
        "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
        "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLC \"Research Center \"Control Systems\""])}
      }
    }
  })
}
