export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо поверить прибор учета"])},
        "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истек межпроверочный интервал"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Истек межпроверочный интервал у прибора учета \"", _interpolate(_list(0)), "\" по адресу ", _interpolate(_list(1))])},
        "ident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ЛС № ", _interpolate(_list(0))])},
        "meter_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ПУ № ", _interpolate(_list(0))])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать поверку"])}
        },
        "verification_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прибор учета считается вышедшим из строя, по нему невозможно передавать показания. Срочно закажите поверку счетчика, или передайте нам Акт поверки, если поверка была недавно проведена"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to check the meter"])},
        "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The check interval has expired"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The check interval has expired at the \"", _interpolate(_list(0)), "\" supply meter at the address ", _interpolate(_list(1))])},
        "ident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ident # ", _interpolate(_list(0))])},
        "meter_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Meter # ", _interpolate(_list(0))])},
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "verification_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The meter is considered to be out of order and it is impossible to transmit readings through it. Urgently order the meter verification, or give us the Verification Certificate if the verification was recently carried out"])}
      }
    }
  })
}
