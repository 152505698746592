import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';

import profileApi from '@/api/profile';

import {
  IState,
  IAccountingInfo,
  IAccountingInfoResult,
} from '@/@types/api/profile';
import type { IAccountsListItem } from '@/@types/components/payment';

export default {
  state: (): IState => ({
    accountingInfoList: [],
    canPayForAllAccounts: null,
  }),

  getters: <GetterTree<IState, any>>{
    getAccountsList({ accountingInfoList }: IState): IAccountsListItem[] {
      return accountingInfoList
        .map((item) => {
          const {
            AccountType: accountType,
            Ident: ident,
            DebtActualDate: date,
            Address: address,
            CompanyName: organization,
            Sum: cost,
            SumFine,
            AccountID: id,
          } = item;

          return {
            title: address,
            ident,
            date,
            address,
            organization,
            cost,
            sumFine: SumFine,
            id,
            isChecked: false,
            value: ident,
            subtitle: `${ident} (${accountType})`,
          };
        })
        .filter((x, i, self) => {
          return self.findIndex((t) => t.ident == x.ident) === i;
        });
    },

    getAccountingInfoAddress({ accountingInfoList }: IState) {
      const addressList = accountingInfoList
        .filter((item) => item.Address)
        .map((item) => {
          const { Address } = item;
          return {
            title: Address,
            value: Address,
          };
        });
      const filteredAddressList = addressList.filter((x, i, self) => {
        return self.findIndex((t) => t.title == x.title) === i;
      });
      // .filter((account) => {
      //   return !this.getAccountSettings(account.AccountID).HideOnMetersPage;
      // })
      return filteredAddressList;
    },
  },

  mutations: <MutationTree<IState>>{
    SET_ACCOUNTING_INFO_LIST(state, data: IAccountingInfo[]) {
      state.accountingInfoList = data;
    },

    SET_CAN_PAY_FOR_ALL_ACCOUNTS(state, data: boolean) {
      state.canPayForAllAccounts = data;
    },
  },

  actions: <ActionTree<IState, any>>{
    /**
     * Запрос на получение информации по начислениям и задолженностям ЛС, по квитанциям и оплате
     * @param {ActionContext<IState, IState>} context
     * @returns {Promise<IAccountingInfoResult>} Промис со списком данных по начислениям и задолженностям ЛС, по квитанциям и оплате
     */
    async getAccountingInfo({
      commit,
    }: ActionContext<IState, IState>): Promise<IAccountingInfoResult> {
      try {
        const result = await profileApi.getAccountingInfo();
        const { Data, CanPayForAllAccounts } = result;
        commit('SET_ACCOUNTING_INFO_LIST', Data);
        commit('SET_CAN_PAY_FOR_ALL_ACCOUNTS', CanPayForAllAccounts);
        return result;
      } catch (error) {
        console.log('Произошла ошибка:', error);
        throw error;
      }
    },
  },
};
