// setRequestList
import { 
  IRequestList,
  IPassCategory,
} from "@/@types/api/requests"
import { GetterTree, MutationTree, ActionTree } from "vuex";

import requestsApi from '@/api/requests';

export interface IState {
  requestList: IRequestList
  passCategoryList: IPassCategory[]
}

export default {
  state: (): IState => ({
    requestList: {
      data: [],
      UpdateKey: null,
      Requests: []
    },
    // TODO: i18n:
    passCategoryList: [
      {
        id: 1,
        title: 'Пешеход'
      },
      {
        id: 2,
        title: 'Мотоцикл'
      },
      {
        id: 3,
        title: 'Легковой'
      },
      {
        id: 4,
        title: 'Газель'
      },
      {
        id: 5,
        title: 'Грузовой'
      },
      {
        id: 6,
        title: 'Миксер'
      },
      {
        id: 7,
        title: 'Велосипед'
      },
    ],
  }),
  getters: <GetterTree<IState, any>>{
  },
  mutations: <MutationTree<IState>>{
    SET_REQUEST_LIST (state, data: IRequestList) {
      state.requestList = data
    },

    SET_UPDATE_KEY (state, data: string) {
      state.requestList.UpdateKey = data
    }
  },
  actions: <ActionTree<IState, any>>{
    async getRequestList({ commit }) {
      return await requestsApi.getList().then((data) => {
        commit('SET_REQUEST_LIST', data)

        return data;
      });
    },

    setUpdateKey({ commit }, updateKey: string) {
      commit('SET_UPDATE_KEY', updateKey)
    },

    fetchRequestTypes() {
      return requestsApi
        .getRequestTypes()
        .then((data) => data);
    },

    sendFile(_, params) {
      return requestsApi
        .addFile(params)
        .then((data) => data);
    },

    createRequest(_, params) {
      return requestsApi
        .createRequest(params)
        .then((data) => data);
    }
  },
}
