import { forEach } from "lodash";

type Path = string;
type Expires = Date | string | number | null;
type CookieName = string;
type CookieValue = string;
type MaxAge = number;

interface Options {
  path?: Path;
  expires?: Expires;
  "max-age"?: MaxAge;
}

export const getCookie = (name: CookieName) => {
      const regexp = new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
      );
      const matches: RegExpMatchArray | null = document.cookie.match(regexp);

      return matches ? decodeURIComponent(matches[1]) : undefined;
    };

export const setCookie = (
      name: CookieName,
      value: CookieValue,
      options = {} as Options
    ): void => {
      options = {
        path: "/",
        // при необходимости добавьте другие значения по умолчанию
        ...options,
      };

      if (options.expires && options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
        value
      )}`;

      forEach(options, (option, key): void => {
        updatedCookie += `; ${key}`;

        // if (!optionValue) {
        updatedCookie += `=${option}`;
        // }
      });

      document.cookie = updatedCookie;
    };

export const deleteCookie = (name: CookieName): void => {
      setCookie(name, "", { "max-age": -1 });
    };