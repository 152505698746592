/**
 * Добавление токена авторизации в localStorage
 *
 * @param {string} token  токен авторизации
 *
 */

export const setAccessToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

/**
 * Получение токена авторизации из localStorage
 *
 * @returns {string}  token  токен авторизации
 *
 */

export const getAccessToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

/**
 * Удаление токена авторизации из localStorage
 *
 */

export const resetAccessToken = (): void => {
  localStorage.removeItem('accessToken');
};

/**
 * Удаление данных store из localStorage
 *
 */

export const resetAccountInfo = (): void => {
  localStorage.removeItem('vuex');
};

// /**
//  * Добавление данных пользователя в localStorage
//  *
//  * @param {ILoginResult | null} data Объект с данными пользователя, для сброса данных передается значение null
//  *
//  */

// export const setUserData = (data: ILoginResult | null): void => {
//   localStorage.setItem('user', JSON.stringify(data));
// };

// /**
//  * Получение данных пользователя из localStorage
//  *
//  * @returns {ILoginResult | null} data Объект с данными пользователя, для сброса данных передается значение null
//  *
//  */

// export const getUserData = (): ILoginResult | null => {
//   const user: string | null = localStorage.getItem('user');

//   if (user && user.length > 0) {
//     return JSON.parse(user);
//   }

//   return null;
// };
