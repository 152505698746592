import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { downloadBlob } from "@/utils/download";

import type {
  IAccountAccountingInfo,
  IAccountingInfo,
  ISumWithComission,
} from '@/@types/api/accounting';

import type {
  IState,
  IDownloadPaymentCheckFilePayload
} from '@/@types/accounting'

import { IIdentsOption } from '@/@types/accounting';
import accountingApi from '@/api/accounting';

export default {
  state: (): IState => ({
    info: null,
  }),

  getters: <GetterTree<IState, any>>{
    getPayments({ info }: IState) {
      return (
        info?.Data.map((item) => {
          return {
            insurancePayments: item.InsurancePayments,
            payment: item.Payments,
            ident: item.Ident,
            address: item.Address,
          };
        }).flat() || []
      );
    },

    getIdentsList({ info }: IState): IIdentsOption[] | [] {
      return (
        info?.Data.map((item: IAccountingInfo) => {
          return {
            title: `${item.Ident || ''} (${item.AccountType || ''})`,
            value: item.Ident,
          };
        }) || []
      );
    },

    getAddressList({ info }: IState): IIdentsOption[] | [] {
      const notEmptyUniqueAddresses =
        new Set(
          info?.Data.filter((item) => item.Address !== null).map(
            (item) => item.Address,
          ),
        ) || [];

      return Array.from(notEmptyUniqueAddresses).map((item: string) => {
        return {
          title: item,
          value: item,
        };
      });
    },
  },

  mutations: <MutationTree<IState>>{
    SET_INFO(state, data: IAccountAccountingInfo) {
      state.info = data;
    },
  },

  actions: <ActionTree<IState, any>>{
    async fetchInfo({ commit }): Promise<IAccountAccountingInfo> {
      return await accountingApi.info().then((response) => {
        commit('SET_INFO', response);

        return response;
      });
    },

    async fetchSumWithComission(
      _,
      payload,
    ): Promise<ISumWithComission> {
      return accountingApi.sumWithComission(payload);
    },

    async downloadCheckFile(
      _,
      { 
        id,
        inJPG,
        paymentType,
        name
      }: IDownloadPaymentCheckFilePayload
    ): Promise<void> {
      return await accountingApi
        .getCheck({
          id,
          inJPG,
          paymentType
        })
        .then((response) => {
          if (!response) {
            return;
          }

          downloadBlob(response, name || id.toString());
        })
        .catch(error => {
          throw Error(`Ошибка скачивания чека: ${error}`);
        })
    }
  },
};
