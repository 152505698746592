import { ICommonResult } from '@/@types/api/common';
import {
  IAccountAccountingInfo,
  IBonusCashFlow,
  ISumWithComission,
  BonusBalance,
  IDownloadPaymentCheckPayload,
  IRequestBonusInfo,
} from '@/@types/api/accounting';
import { http } from '@/plugins/axios';

const endPoint = '/Accounting';

/**
 * Возвращает информацию по начислениям и задолженностям ЛС, информацию по
 * квитанциям и оплате.
 *
 * Важно: возможна ситуация когда у пользователя подключено два лицевых счета
 * по кр и жку с одним номером. В таком случае список квитанций и оплат будет
 * добавлен только к одному из лицевых счетов, т.к. нет возможности их
 * разделить между лицевыми счетами. При отображении пользователю квитанции и
 * оплаты следует объединять между такими лс.
 *
 * @returns {Promise<IAccountAccountingInfo>}
 */
const info = (): Promise<IAccountAccountingInfo> => {
  return http.get(`${endPoint}/Info`);
};

/**
 * Возвращает информацию историю начисления и списания бонусов по лицевому счету.
 *
 * @param {number} id ID лицевого счета
 * @returns {Promise<IBonusCashFlow>}
 */
const getAccountBonusBalanceHistory = (id: number): Promise<IBonusCashFlow> => {
  return http.get(`${endPoint}/GetAccountBonusBalanceHistory`, {
    params: { id },
  });
};

/**
 * Возвращает сумму с комиссией
 *
 * @param {number} sum
 * @returns {Promise<ISumWithComission>}
 */
const sumWithComission = (sum: number): Promise<ISumWithComission> => {
  return http.get(`${endPoint}/SumWithComission`, {
    params: { sum },
  });
};

/**
 * Возвращает бонусный баланс лицевого счета.
 *
 * @param {number} id
 * @returns {Promise<BonusBalance>}
 */
const getAccountBonusBalance = (id: number): Promise<BonusBalance> => {
  return http.get(`${endPoint}/GetAccountBonusBalanceHistory`, {
    params: { id },
  });
};

/**
 * Возвращает файл чека оплаты по указанному id
 *
 * @param {IDownloadPaymentCheckPayload} payload
 * @returns {Promise<Blob>}
 */
const downloadPaymentCheck = (
  payload: IDownloadPaymentCheckPayload,
): Promise<Blob> => {
  return http.get(`${endPoint}/DownloadPaymentCheck`, {
    params: payload,
    responseType: 'blob',
  });
};

/**
 * Отключает страхование для указанного лицевого счета.
 *
 * @param {number} accountId ID лицевого счета
 * @returns {Promise<ICommonResult>}
 */
const disableAccountInsurance = (accountId: number): Promise<ICommonResult> => {
  return http.post(`${endPoint}/DisableAccountInsurance`, {
    accountId,
  });
};

/**
 * Отмена страховки и возврат денег для указанного лицевого счета.
 * Доступна 14 дней после оплаты
 *
 * @param {string} ident номер лицевого счета
 * @returns {Promise<ICommonResult>}
 */
const cancelInsurance = (ident: string): Promise<ICommonResult> => {
  return http.post(`${endPoint}/CancelInsurance`, {
    ident,
  });
};

/**
 * Возвращает количество бонусов, которые могут быть списаны при оплате заявки
 * с указаннм ид.
 *
 * @param {number} id ID заявки
 * @returns {Promise<IRequestBonusInfo>}
 */
const getPaidRequestAllowedBonuses = (
  id: number,
): Promise<IRequestBonusInfo> => {
  return http.get(`${endPoint}/GetAccountBonusBalanceHistory`, {
    params: { id },
  });
};

/**
 * https://wiki.sm-center.ru:1085/doku.php?id=api_core#%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D0%BB%D0%B5%D1%80_bills_-_%D0%BA%D0%B2%D0%B8%D1%82%D0%B0%D0%BD%D1%86%D0%B8%D0%B8
 * @param {IDownload} payload 
 * @returns {Promise} Blob
 */
const getCheck = async ({id, inJPG = 0, paymentType}: IDownloadPaymentCheckPayload): Promise<Blob> => {
  return await http.get(`${endPoint}/DownloadPaymentCheck`, {
    responseType: 'blob',
    params: {
      id,
      inJPG,
      paymentType
    }}

    );
  };

export default {
  info,
  getAccountBonusBalanceHistory,
  sumWithComission,
  getAccountBonusBalance,
  downloadPaymentCheck,
  disableAccountInsurance,
  cancelInsurance,
  getPaidRequestAllowedBonuses,
  getCheck
  // downloadCheck,
};
