import {
  IGetList,
  IGetDetails,
  IGetUpdates,
  ICreateRequest,
  IIDResult,
  IRequestCreationCompleted,
  IGetFile,
  IAddMessage,
  IAddFile,
  ICloseRequest,
  ICancelRequest,
  IGetRequestTypes,
  IGetRequestAdditionalFields,
  IGetVehicleMarks,
  ISendPaidRequestCompleteCodeOnline,
  ISendPaidRequestCompleteCodeCash,
  ISetReadedFlag,
  ICheckRequestSumIsVerified,
  IGetHouses,
  IGetHouseData,
  IAddBotCall,
  IRequestsUpdate,
} from '@/@types/api/requests';
import { http as axios } from '@/plugins/axios';

const endPoint = "/Requests";

const getList: IGetList = async () => {
  return await axios.get(`${endPoint}/List`);
};

const getDetails: IGetDetails = async (id) => {
  return await axios.get(`${endPoint}/Details/${id}`);
};

const getUpdates = async (payload: IGetUpdates): Promise<IRequestsUpdate> => {
  return await axios.post(`${endPoint}/GetUpdates`, payload);
};

const createRequest = async (payload: ICreateRequest): Promise<IIDResult> => {
  return await axios.post(`${endPoint}/New/`, payload)
};

const requestCreationCompleted: IRequestCreationCompleted = async (payload) => {
  return await axios.post(`${endPoint}/RequestCreationCompleted`, payload);
};

const getFile: IGetFile = async (id) => {
  return await axios.get(`${endPoint}/File/${id}`, {
    responseType: "blob",
  });
};

const addMessage: IAddMessage = async (payload) => {
  return await axios.post(`${endPoint}/AddMessage/`, payload);
};

const addFile: IAddFile = async (payload) => {
  return await axios.postForm(`${endPoint}/AddFile/`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const closeRequest: ICloseRequest = async (payload) => {
  return await axios.post(`${endPoint}/Close/`, payload);
};

const cancelRequest: ICancelRequest = async (payload) => {
  return await axios.post(`${endPoint}/CancelRequest/`, payload);
};

const getRequestTypes: IGetRequestTypes = async () => {
  return await axios.get(`${endPoint}/RequestTypes/`)
    .then((result: any) => {
      return result.Data
    });
};

const getRequestAdditionalFields: IGetRequestAdditionalFields = async () => {
  return await axios.get(`${endPoint}/RequestAdditionalFields/`);
};

const getVehicleMarks: IGetVehicleMarks = async () => {
  return await axios.get(`${endPoint}/VehicleMarks/`);
};

const sendPaidRequestCompleteCodeOnline: ISendPaidRequestCompleteCodeOnline =
  async (payload) => {
    return await axios.post(`${endPoint}/SendPaidRequestCompleteCodeOnline/`, {
      payload,
    });
  };

const sendPaidRequestCompleteCodeCash: ISendPaidRequestCompleteCodeCash =
  async (payload) => {
    return await axios.post(`${endPoint}/SendPaidRequestCompleteCodeCash/`, {
      payload,
    });
  };

const setReadedFlag: ISetReadedFlag = async (requestId) => {
  return await axios.post(`${endPoint}/SetReadedFlag/`, { requestId });
};

const checkRequestSumIsVerified: ICheckRequestSumIsVerified = async (
  requestId
) => {
  return await axios.get(`${endPoint}/CheckRequestSumIsVerified/${requestId}`);
};

const getHouses: IGetHouses = async (street) => {
  return await axios.get(`${endPoint}/Houses/`, {
    params: { street },
  });
};

const getHouseData: IGetHouseData = async (id) => {
  return await axios.get(`${endPoint}/HouseData/`, {
    params: { id },
  });
};

const addBotCall: IAddBotCall = async (payload) => {
  return await axios.post(`${endPoint}/AddBotCall/`, { payload });
};

export default {
  getList,
  getDetails,
  getUpdates,
  createRequest,
  requestCreationCompleted,
  getFile,
  addMessage,
  addFile,
  closeRequest,
  cancelRequest,
  getRequestTypes,
  getRequestAdditionalFields,
  getVehicleMarks,
  sendPaidRequestCompleteCodeOnline,
  sendPaidRequestCompleteCodeCash,
  setReadedFlag,
  checkRequestSumIsVerified,
  getHouses,
  getHouseData,
  addBotCall,
};
