import { IGetList } from '@/@types/api/requests';
import { http } from '@/plugins/axios';

const endPoint = "/EmergencyWorks";

const getList: IGetList = async () => {
  return await http.get(`${endPoint}/List`);
} 

export default {
  getList,
}