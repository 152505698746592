export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "pending_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидают оплаты"])},
        "pay_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить все"])},
        "idents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["нет лицевых счетов"]), _normalize([_interpolate(_named("n")), " лицевой счет"]), _normalize([_interpolate(_named("n")), " лицевых счета"]), _normalize([_interpolate(_named("n")), " лицевых счетов"])])},
        "includes_comission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["В сумму оплаты включена комиссия ", _interpolate(_list(0)), " ₽"])},
        "add_personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])}
      },
      "en": {
        "pending_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting payment"])},
        "pay_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay all"])},
        "idents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no idents"]), _normalize([_interpolate(_named("n")), " ident"]), _normalize([_interpolate(_named("n")), " idents"])])},
        "includes_comission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The payment amount includes a commission of ", _interpolate(_list(0)), " ₽"])},
        "add_personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
      }
    }
  })
}
