// https://dev.to/zafaralam/app-wide-toast-notifications-in-vue-3-2fp9

import {
  IPush,
  TCreatePush,
  TRemovePush,
  ISnackbar,
  TCreateSnackbar,
  TRemoveSnackbar,
} from "./@types";

import { ref } from "vue";
import { generateUUID } from "@/utils/generator";

export default function useNotifications() {
  const snackbarDefaults = {
    duration: 5000,
    vertical: false,
    showClose: false,
  };

  const pushes = ref<IPush[]>([]);
  const snackbars = ref<ISnackbar[]>([]);

  // Push
  const createPush: TCreatePush = (options) => {
    pushes.value.push({
      type: "info",
      message: "",
      footer: "",
      showClose: true,
      duration: 5000,
      ...options,
      id: generateUUID(),
    });
  };

  const removePush: TRemovePush = (id) => {
    const index = pushes.value.findIndex((item) => item.id === id);

    if (index !== -1) {
      pushes.value.splice(index, 1);
    }
  };

  // Snackbar
  const createSnackbar: TCreateSnackbar = (options) => {
    snackbars.value.push({
      ...snackbarDefaults,
      ...options,
      id: generateUUID(),
    });
  };

  const removeSnackbar: TRemoveSnackbar = (id) => {
    const index = snackbars.value.findIndex((item) => item.id === id);

    if (index !== -1) {
      snackbars.value.splice(index, 1);
    }
  };

  return {
    pushes,
    snackbars,
    createPush,
    removePush,
    createSnackbar,
    removeSnackbar,
  };
}
