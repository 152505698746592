import { IGetAccountingInfo } from '@/@types/api/profile';
import { http } from '@/plugins/axios';

const endPoint = '/accounting';

/**
 * Получение информации по начислениям и задолженностям ЛС, информации по квитанциям и оплате
 * @returns {Promise<IAccountAccountingInfoResult>} Промис с данными по начислениям и задолженностям ЛС, по квитанциям и оплате
 */
const getAccountingInfo: IGetAccountingInfo = () => {
  return http.get(`${endPoint}/info`);
};

export default {
  getAccountingInfo,
};
