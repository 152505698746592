import useNotifications from './notifications';
import TheNotifications from '@/components/ui/notifications/TheNotifications/TheNotifications.vue';

export default {
  install: (app: any) => {
    const {
      pushes,
      snackbars,
      createPush,
      removePush,
      createSnackbar,
      removeSnackbar,
    } = useNotifications();

    // app.config.globalProperties.$notification = TODO
    app.provide('notifications-plugin', {
      pushes,
      snackbars,
      createPush,
      removePush,
      createSnackbar,
      removeSnackbar,
    });
    app.component('TheNotifications', TheNotifications);
  },
};
