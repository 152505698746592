import { ICSSVariables } from '@/@types/common';

export default <ICSSVariables>{
  light: {
    Background: '#f6f6f6', // Основной цвет фона
    Surface: '#d1e6d0', // Поверхность custom
    SurfaceVariant: '#cbd7d3', // Альтернативная поверхность custom
    SurfaceVariant2: '#ebebec', // Альтернативная поверхность 2
    SurfaceInverse: '#353535', // Альтернативная поверхность инверсия
    SurfaceBlanket: '#ffffff', // Наложение: #000000 20% #FFFFFF ?

    // Dropdown shadow
    DropdownRGB: '32, 46, 83',

    // Text
    // title: '#262644', // Все заголовки
    Title: '#262644',
    OnSurfaceTitle: '#262644',
    // body: '#262644', // Основной наборный текст
    Body: '#262644',
    OnSurfaceBody: '#262644',
    // label: '#55586A, // Подписи', подсказки
    Label: '#55586a',
    OnSurfaceLabel: '#55586a',
    // additional: '#7E8CA0', // Фоновый текст оформления
    Additional: '#7e8ca0',
    OnSurfaceAdditional: '#7e8ca0',
    // disabled: '#DFDFDF', // Цвет подписей недоступных элементов
    Disabled: '#dfdfdf',
    OnSurfaceDisabled: '#dfdfdf',
    // bodyInverse: '#F3F3F3', // Основной наборный текст
    BodyInverse: '#f3f3f3',
    OnSurfaceBodyInverse: '#f3f3f3',

    // Primary, secondary & tertiary
    Primary: '#3ea635', // Основной акцентный цвет бренда enable, hover, press custom
    OnPrimary: '#ffffff', // Контент на primary цвете custom
    PrimaryDisable: '#c5c5c5', // Primary кнопка в состоянии недоступном
    OnPrimaryDisable: '#e4e4e4', // Цвет контента на primary кнопках в состоянии недоступном
    PrimaryInverse: '#3ea635', // Основной акцентный цвет бренда инверсия custom ? + #FFFFFF 75%
    Secondary: '#696a71', // Дополнительный акцентный цвет
    OnSecondary: '#ffffff', // Контент на secondary цвете
    SecondaryHover: '#494c57', // Элементы в состоянии наведения
    SecondaryPress: '#696a71', // Элементы в состоянии нажатия
    SecondaryDisable: '#9698a3', // Secondary кнопка в состоянии недоступном
    OnSecondaryDisable: '#b2b5c7', // Цвет контента на secondary кнопках в состоянии недоступном
    Tertiary: '#69a4a2', // Третичный цвет бренда (если есть) enable, hover', press custom
    OnTertiary: '#262644', // Контент на третичном цвете бренда enable, hover', press custom
    TertiaryDisable: '#c5c5c5', // Третичная кнопка в состоянии недоступном
    OnTertiaryDisable: '#e4e4e4', // Цвет контента на третичной кнопке в состоянии недоступном

    // Accent
    Accent: '#1e5945', // Акцентный цвет бренда enable, hover', press custom
    OnAccent: '#ffffff', // Контент на акцентном цвете
    AccentDisable: '#c5c5c5', // Акцентная кнопка в состоянии недоступном
    OnAccentDisable: '#e4e4e4', // Цвет контента на акцентной кнопке в состоянии недоступном

    // Container & elements
    Elm95: '#f3f3f3',
    Elm90: '#dfe0e2',
    Elm80: '#d0d0d3',
    Elm70: '#bebebf',
    Elm60: '#a1a1a1',
    Elm0: '#212121',
    ElmEnable: '#212121',
    ElmHover: '#3ea635',
    ElmDisable: '#dfdfdf',

    // Icon
    Icon: '#1b1b1f', // Основной цвет иконок
    IconHover: '#3ea635', // Маленькие элементы в состоянии наведения. Это Primary
    IconPress: '#3ea635', // Маленькие элементы в состоянии наведения. Это Primary
    IconLabel: '#6e6e6e', // Дополнительный цвет иконок
    IconAdditional: '#a5a5a5', // Фоновый цвет иконок
    IconDisable: '#ececec', // Недоступные иконки
    IconInverse: '#f3f3f3', // Инвертированный цвет иконок

    // Border
    Border: '#a4a4a4', // Разделители
    BorderHover: '#3ea635', // Обводка для кнопок в состоянии наведения custom
    BorderPress: '#a4a4a4', // Обводка для кнопок в состоянии нажатия
    BorderDisable: '#dedede', // Обводка для кнопок в состоянии недоступном

    // Selection
    SelectionPrimary1: '#ffffff', // Подсветка выбранного элемента. Используется в navigation bar. Наложение: FFFFFF 85% custom
    SelectionPrimary2: '#ffffff', // Подсветка выбранного элемента. Используется в navigation bar. Наложение: FFFFFF 63% custom
    SelectionPrimary3: '#ffffff', // Подсветка выбранных табов. Наложение: FFFFFF 25% custom
    SelectionSecondary: '#595e72', // Подсветка secondary + 35% alfa
    SelectionSecondary2: '#595e72', // Подсветка secondary 2 + 10% alfa

    // Illustrations
    // Цвет используемый в иллюстрациях
    Brand1: '#afd6aa', // custom
    Brand2: '#679873', // custom
    Brand3: '#1e5945', // custom
    Brand4: '#1a3a2e', //custom
    Skin: '#ddb8af', // custom
    SignError: '#de3730', // custom
    SignSuccess: '#33b576', // custom
    OnSign: '#ffffff', // custom
    Back: '#3a342e', // custom
    Back2: '#110f0d', // custom
    Attention1: '#e4404a', // Attention 1. Цвет используемый в иллюстрациях
    Attention2: '#ff9b9d',
    SubAttention1: '#09183f',
    SubAttention2: '#4f5b78',
    SubAttention3: '#6a7797',
    SubAttention4: '#d7d7d7',
    SubAttention5: '#fbede8',
    IllustrationSurface: '#cbd7d3',

    // Semantic
    Success: '#99aa38', // Цвет статусов с флагом “success” (successBtn)
    OnSuccess: '#ffffff', // Цвет контента на компонентах с флагом “success” (onSuccessBtn)
    SuccessHover: '#bcd046', // Интерактивные элементы в состоянии hover (successBtnHover)
    SuccessPress: '#14591d', // Интерактивные элементы в состоянии press (successBtnPress)
    SuccessStatus: '#33b576', // Цвет тегов

    Error: '#de3730', // Цвет статусов с флагом “error”
    OnError: '#ffffff', // Цвет контента на компонентах с флагом “error”
    ErrorHover: '#db2f2f', // Интерактивные элементы в состоянии hover
    ErrorPress: '#ba1a1a', // Интерактивные элементы в состоянии press
    ErrorDisable: '#8399c0', // Интерактивные элементы в состоянии недоступном

    Warning: '#faad14', // Цвет статусов с флагом “warning”

    Info: '#336bff', // Цвет статусов с флагом “Info”

    Neutral: '#636675', // Цвет статусов с флагом “Neutral”

    // Background semantic
    BGSuccess: '#e7f8ec', // Фон под группой элементов', отображающих успешный результат
    BGError: '#fff2f0', // Фон под группой элементов', отображающих ошибку
    BGWarning: '#fffbe6', // Фон под группой элементов', отображающих предупреждение
    BGInfo: '#eff0ff', // Фон под группой элементов', отображающих информацию
    BGNeutral: '#ededed', // Фон под группой элементов', отображающих нейтральное состояние

    // Stroke semantic
    StrokeSuccess: '#adebcd', // Обводка для алертов отображающих успешный результат
    StrokeError: '#ffccc7', // Обводка для алертов отображающих ошибку
    StrokeWarning: '#ffe58f', // Обводка для алертов отображающих предупреждение
    StrokeInfo: '#a0acf5', // Обводка для алертов отображающих информацию
    StrokeNeutral: '#b9b9b9', // Обводка для алертов отображающих нейтральное состояние
  },
  dark: {
      // Backgrounds
    Background: '#201a17', // Основной цвет фона
    Surface: '#18120F', // Поверхность custom
    SurfaceVariant: '#352d27', // Альтернативная поверхность custom
    SurfaceVariant2: '#4a3a33', // Альтернативная поверхность 2
    SurfaceInverse: '#d0c4bf', // Альтернативная поверхность инверсия
    SurfaceBlanket: '#ffffff', // Наложение: #000000 20% #FFFFFF ?

    // Text
    // title: '#D0C4BF', // Все заголовки
    OnBgTitle: '#d0c4bf',
    OnSurfaceTitle: '#d0c4bf',
    // body: '#D0C4BF', // Основной наборный текст
    OnBgBody: '#d0c4bf',
    OnSurfaceBody: '#d0c4bf',
    // label: '#998F8A, // Подписи', подсказки
    OnBgLabel: '#998f8a',
    OnSurfaceLabel: '#998f8a',
    // -Additional: '#655D58', // Фоновый текст оформления
    OnBgAdditional: '#655d58',
    OnSurfaceAdditional: '#655d58',
    // disabled: '#362F2B', // Цвет подписей недоступных элементов
    OnBgDisabled: '#362f2b',
    OnSurfaceDisabled: '#362f2b',
    // bodyInverse: '#201A17', // Основной наборный текст
    OnBgBodyInverse: '#201a17',
    OnSurfaceBodyInverse: '#201a17',

    // Primary, secondary & tertiary
    Primary: '#b28864', // Основной акцентный цвет бренда enable, hover', press custom
    OnPrimary: '#201a17', // Контент на primary цвете custom
    PrimaryDisable: '#392f2a', // Primary кнопка в состоянии недоступном
    OnPrimaryDisable: '#594941', // Цвет контента на primary кнопках в состоянии недоступном
    PrimaryInverse: '#944a01', // Основной акцентный цвет бренда инверсия custom
    Secondary: '#3a3330', // Дополнительный акцентный цвет
    OnSecondary: '#d0c4bf', // Контент на secondary цвете
    SecondaryHover: '#453d3a', // Элементы в состоянии наведения
    SecondaryPress: '#d0c4bf', // Элементы в состоянии нажатия
    SecondaryDisable: '#594941', // Secondary кнопка в состоянии недоступном
    OnSecondaryDisable: '#b2b5c7', // Цвет контента на secondary кнопках в состоянии недоступном
    Tertiary: '#c8ca94', // Третичный цвет бренда (если есть) enable, hover', press custom
    OnTertiary: '#31330b', // Контент на третичном цвете бренда enable, hover', press custom
    TertiaryDisable: '#392f2a', // Третичная кнопка в состоянии недоступном
    OnTertiaryDisable: '#392f2a', // Цвет контента на третичной кнопке в состоянии недоступном

    // Accent
    Accent: '#e4bfa8', // Акцентный цвет бренда enable, hover', press custom
    OnAccent: '#422b1b', // Контент на акцентном цвете
    AccentDisable: '#392f2a', // Акцентная кнопка в состоянии недоступном
    OnAccentDisable: '#594941', // Цвет контента на акцентной кнопке в состоянии недоступном

    // Container & elements
    Elm95: '#fbeee8',
    Elm90: '#ece0da',
    Elm80: '#d0c4bf',
    Elm70: '#b4a9a4',
    Elm60: '#998f8a',
    Elm0: '#000000',
    ElmEnable: '#d0c4bf',
    ElmHover: '#b28864',
    ElmDisable: '#392f2a',

    // Icon
    Icon: '#d0c4bf', // Основной цвет иконок
    IconHover: '#b28864', // Маленькие элементы в состоянии наведения. Это Primary
    IconPress: '#b28864', // Маленькие элементы в состоянии наведения. Это Primary
    IconLabel: '#998f8a', // Дополнительный цвет иконок
    IconAdditional: '#655d58', // Фоновый цвет иконок
    IconDisable: '#392f2a', // Недоступные иконки
    IconInverse: '#201a17', // Инвертированный цвет иконок

    // Border
    Border: '#4d4541', // Разделители
    BorderHover: '#866e64', // Обводка для кнопок в состоянии наведения custom
    BorderPress: '#4d4541', // Обводка для кнопок в состоянии нажатия
    BorderDisable: '#392f2a', // Обводка для кнопок в состоянии недоступном

    // Selection
    SelectionPrimary1: '#000000', // Подсветка выбранного элемента. Используется в navigation bar. Наложение: FFFFFF 85% custom
    SelectionPrimary2: '#000000', // Подсветка выбранного элемента. Используется в navigation bar. Наложение: FFFFFF 63% custom
    SelectionPrimary3: '#000000', // Подсветка выбранных табов. Наложение: FFFFFF 25% custom
    SelectionSecondary: '#3a3330', // Подсветка secondary + 35% alfa
    SelectionSecondary2: '#3a3330', // Подсветка secondary 2 + 10% alfa

    // Illustrations
    // Цвет используемый в иллюстрациях
    Brand1: '#e4bfa8', // custom
    Brand2: '#a6866b', // custom
    Brand3: '#7e684d', // custom
    Brand4: '#3a342e', //custom
    Skin: '#a58766', // custom
    SignError: '#ff897d', // custom
    SignSuccess: '#ced173', // custom
    OnSign: '#2c1204', // custom
    Back: '#332d2b', // custom
    Back2: '#231f1c', // custom
    Attention1: '#c95044', // Attention 1. Цвет используемый в иллюстрациях
    Attention2: '#e37469',
    SubAttention1: '#6b6259',
    SubAttention2: '#d1a48b',
    SubAttention3: '#e4bfa8',
    SubAttention4: '#d0c4bf',
    SubAttention5: '#d0c4bf',
    IllustrationSurface: '#352d27',

    // Semantic
    Success: '#ced173', // Цвет статусов с флагом “success”
    OnSuccess: '#31330b', // Цвет контента на компонентах с флагом “success”
    SuccessHover: '#bcc055', // Интерактивные элементы в состоянии hover
    SuccessPress: '#ced173', // Интерактивные элементы в состоянии press
    SuccessStatus: '#ced173', // Цвет тегов

    Error: '#ff897d', // Цвет статусов с флагом “error”
    OnError: '#690005', // Цвет контента на компонентах с флагом “error”
    ErrorHover: '#f27669', // Интерактивные элементы в состоянии hover
    ErrorPress: '#ff897d', // Интерактивные элементы в состоянии press
    ErrorDisable: '#392f2a', // Интерактивные элементы в состоянии недоступном

    Warning: '#d89614', // Цвет статусов с флагом “warning”

    Info: '#84a6c5', // Цвет статусов с флагом “Info”

    Neutral: '#ada692', // Цвет статусов с флагом “Neutral”

    // Background semantic
    BGSuccess: '#3e3928', // Фон под группой элементов', отображающих успешный результат
    BGError: '#3f2e25', // Фон под группой элементов', отображающих ошибку
    BGWarning: '#443421', // Фон под группой элементов', отображающих предупреждение
    BGInfo: '#2c3235', // Фон под группой элементов', отображающих информацию
    BGNeutral: '#393932', // Фон под группой элементов', отображающих нейтральное состояние

    // Stroke semantic
    StrokeSuccess: '#78794a', // Обводка для алертов отображающих успешный результат
    StrokeError: '#784a3d', // Обводка для алертов отображающих ошибку
    StrokeWarning: '#a87d1b', // Обводка для алертов отображающих предупреждение
    StrokeInfo: '#49646d', // Обводка для алертов отображающих информацию
    StrokeNeutral: '#65635b', // Обводка для алертов отображающих нейтральное состояние
  }
}