import {
  createRouter,
  RouteRecordRaw,
  createWebHashHistory,
} from 'vue-router';

import { unauthorizedRoutes } from './unauthorizedRoutes';
import { authorizedRoutes } from './authorizedRoutes';
import { getAccessToken } from '@/utils/authService';

const routes: Array<RouteRecordRaw> = [
  ...authorizedRoutes,
  ...unauthorizedRoutes,
];

export const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, __, savedPosition) {
    if (savedPosition && to.name === 'Requests') {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 2000);
      });
    } else {
      return { top: 0 };
    }
  },
});

const isAuthenticated = () => {
  return getAccessToken(); // Проверка, авторизован ли пользователь
};

// Middleware для проверки авторизации
router.beforeEach((to, from, next) => {
  console.log('--- router.beforeEach ---');
  
  const { requiresAuth, title } = to.meta;

  if (requiresAuth && !isAuthenticated()) {
    // Если маршрут требует аутентификации и пользователь не авторизован

    // Сохраняем путь, который пользователь хотел посетить
    const returnUrl = to.fullPath;

    // Перенаправляем на страницу логина с передачей returnUrl в качестве параметра
    console.log('returnUrl:', returnUrl);
    
    next({ name: 'Login', query: { returnUrl } });
    return;
  }

  // Устанавливаем заголовок страницы
  document.title = title 
    ? `Личный кабинет жителя | ${title}` 
    : 'Личный кабинет жителя';

  // Иначе пропускаем запрос
  next();
});

// Код для возврата на страницу
// <script setup>
// import { useRouter } from 'vue-router';

// const router = useRouter();
// const returnUrl = $route.query.returnUrl;

// Логика аутентификации

// Перенаправление пользователя после успешной аутентификации
// if (isAuthenticated()) {
// Перенаправляем на предыдущую страницу или на главную
// router.push(returnUrl || '/');
// }
// </script>
