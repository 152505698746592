import { IAuthorizedRoute } from './routesTypes';
import { RouteRecordRaw } from 'vue-router';
import i18n from '@/plugins/i18n';

const routes: RouteRecordRaw[] = [
  // GENERAL PAGES -------------------------------------------------------------
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/HomeView.vue'),
    meta: {
      title: i18n.global.t('menu_home'),
      pageName: 'home',
    },
  },

  // Оплата
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/payment/PaymentView.vue'),
    meta: {
      title: i18n.global.t('menu_payment'),
    }
  },
  {
    path: '/payment/history',
    name: 'PaymentHistory',
    component: () => import('../views/payment/PaymentHistory.vue'),
    meta: {
      title: i18n.global.t('payment_history'),
    }
  },
  {
    path: '/payment/account/:id',
    name: 'PaymentAccount',
    component: () => import('../views/payment/PaymentAccount.vue'),
    meta: {
      title: i18n.global.t('payment_account')
    },
  },
  {
    path: '/payment/all',
    name: 'PaymentAll',
    component: () => import('../views/payment/PaymentAll.vue'),
    meta: {
      title: i18n.global.t('payment_all')
    },
  },

  // Заявки
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../views/requests/RequestsView.vue'),
    meta: {
      title: i18n.global.t('menu_requests'),
      pageName: 'requests',
    },
  },
  {
    path: '/request/:id',
    name: 'RequestDetail',
    component: () => import('../views/requests/RequestsDetailView.vue'),
    meta: {
      title: i18n.global.t('request_number'),
      pageName: 'request-detail',
    },
  },
  {
    path: '/new-request',
    name: 'NewRequest',
    component: () => import('../views/requests/NewRequestView.vue'),
    meta: {
      title: i18n.global.t('new_request'),
      // pageName: 'requests'
    },
  },

  // Показания
  {
    path: '/meters',
    name: 'Meters',
    component: () => import('../views/meters/MetersView.vue'),
    meta: {
      title: i18n.global.t('menu_meters')
    }
  },
  {
    path: '/meters/info',
    name: 'MetersInfo',
    component: () => import('../views/meters/MetersInfo.vue'),
    meta: {
      title: i18n.global.t('meters_info')
    }
  },
  {
    path: '/meters/meter/:id',
    name: 'MeterInfo',
    component: () => import('../views/meters/MeterInfo.vue'),
    meta: {
      title: i18n.global.t('meter_name')
    }
  },
  {
    path: '/meters/meter/:id/meters-history',
    name: 'MetersHistory',
    component: () => import('@/views/meters/MetersHistory.vue'),
    meta: {
      layout: 'DefaultLayout',
      title: i18n.global.t('meters_history')
    },
  },

  // Сервис
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/home/HomeView.vue'),
    meta: {
      title: i18n.global.t('menu_services')
    }
  },

  // Профиль
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile/ProfileInfo.vue'),
    meta: {
      title: i18n.global.t('profile')
    }
  },
  {
    path: '/profile/settings',
    name: 'Settings',
    component: () => import('../views/profile/UserSettings.vue'),
    meta: {
      title: i18n.global.t('settings')
    }
  },
  {
    path: '/profile/settings/password',
    name: 'SettingsPassword',
    component: () => import('../views/profile/UserSettingsPassword.vue'),
    meta: {
      title: i18n.global.t('change_password')
    }
  },

  // INNER PAGES ---------------------------------------------------------------

  // События
  {
    path: '/announcements',
    name: 'Announcements',
    component: () => import('@/views/home/AnnouncementsView.vue'),
    meta: {
      layout: 'DefaultLayout',
      title: i18n.global.t('events'),
    },
  },
  {
    path: '/announcement/:id',
    name: 'AnnouncementDetail',
    component: () => import('@/views/home/AnnouncementDetailView.vue'),
    meta: {
      layout: 'DefaultLayout',
      title: i18n.global.t('event_detail'),
    },
  },

  // Новости
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/home/NewsView.vue'),
    meta: {
      layout: 'DefaultLayout',
      title: i18n.global.t('news'),
    },
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: () => import('@/views/home/NewsDetailView.vue'),
    meta: {
      layout: 'DefaultLayout',
      title: i18n.global.t('news_detail'),
    },
  },

  // Профиль
  {
    path: '/profile/rights-management',
    name: 'RightsManagement',
    component: () => import('../views/profile/RightsManagement.vue'),
    meta: {
      title: i18n.global.t('right_management')
    }
  },
  {
    path: '/profile/rights-management/new-user',
    name: 'NewUser',
    component: () => import('../views/profile/NewUser.vue'),
    meta: {
      title: i18n.global.t('add_new_user')
    }
  },
  {
    path: '/profile/personal-info',
    name: 'PersonalInfo',
    component: () => import('../views/profile/PersonalInfo.vue'),
    meta: {
      title: i18n.global.t('personal_info')
    }
  },
  {
    path: '/payment/invoices',
    name: 'PaymentInvoices',
    component: () => import('../views/payment/InvoicesView.vue'),
    meta: {
      title: i18n.global.t('invoices')
    }
  },

  // UI-Kit --------------------------------------------------------------------
  {
    path: '/ui-kit/',
    name: 'UiKit',
    component: () => import('@/views/uiKit/IndexView.vue'),
    meta: {
      layout: 'UiKitLayout',
    },
    children: [
      {
        path: 'colors',
        name: 'UiKit.Colors',
        component: () => import('@/views/uiKit/ColorsView/ColorsView.vue'),
        meta: {
          title: 'Цветовая система',
        },
      },
      {
        path: 'typography',
        name: 'UiKit.Typography',
        component: () =>
          import('@/views/uiKit/TypographyView/TypographyView.vue'),
        meta: {
          title: 'Типографика',
        },
      },
      {
        path: 'icons',
        name: 'UiKit.Icons',
        component: () => import('@/views/uiKit/IconsView/IconsView.vue'),
        meta: {
          title: 'Иконки',
        },
      },
      {
        path: 'buttons',
        name: 'UiKit.Buttons',
        component: () => import('@/views/uiKit/ButtonsView/ButtonsView.vue'),
        meta: {
          title: 'Кнопки',
        },
      },
      {
        path: 'badges',
        name: 'UiKit.Badges',
        component: () => import('@/views/uiKit/BadgesView/BadgesView.vue'),
        meta: {
          title: 'Баджи и ярлыки',
        },
      },
      {
        path: 'lists',
        name: 'UiKit.Lists',
        component: () => import('@/views/uiKit/ListsView/ListsView.vue'),
        meta: {
          title: 'Списки',
        },
      },
      {
        path: 'cards',
        name: 'UiKit.Cards',
        component: () => import('@/views/uiKit/CardsView/CardsView.vue'),
        meta: {
          title: 'Карточки',
        },
      },
      {
        path: 'forms',
        name: 'UiKit.Forms',
        component: () => import('@/views/uiKit/FormsView/FormsView.vue'),
        meta: {
          title: 'Формы',
        },
      },
      {
        path: 'other',
        name: 'UiKit.Other',
        component: () => import('@/views/uiKit/OtherView/OtherView.vue'),
        meta: {
          title: 'Разное',
        },
      },
      {
        path: 'chat',
        name: 'UiKit.Chat',
        component: () => import('@/views/uiKit/ChatView/ChatView.vue'),
        meta: {
          title: 'Чат',
        },
      },
    ],
  },

  // этот роут должен быть последним в массиве роутов для корректного редиректа на 404
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/notFound/NotFoundView.vue'),
    meta: {
      title: i18n.global.t('not_found')
    },
  },
];

export const authorizedRoutes: IAuthorizedRoute[] = routes.map((route) => ({
  ...route,
  meta: { ...route.meta, requiresAuth: true },
}));
