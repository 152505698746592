import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import * as authService from '@/utils/authService';

/**
 * @type  Token  Тип, определяющий структуру токена
 */
// type Token = string | null;

/**
 * @interface  IHeaders  Интерфейс, определяющий структуру заголовков запроса
 */
interface IHeaders {
  [key: string]: string;
}

/**
 * @interface  IAxiosPluginConfig  Интерфейс, определяющий опциональную конфигурацию для плагина Axios
 */
interface IAxiosPluginConfig {
  headers?: IHeaders;
}

/**
 * @class  Http  Класс, представляющий обертку над Axios для выполнения HTTP-запросов
 */
class Http {
  /**
   * @member  {AxiosInstance}  instance  Экземпляр Axios для выполнения HTTP-запросов
   */
  public instance: AxiosInstance;

  /**
   * @member  {Token}  token  Информация о токене для управления аутентификацией
   */
  // public token: Token;

  /**
   * Создает экземпляр класса Http.
   * @constructor
   * @param        {IAxiosPluginConfig}  [config]  Опциональная конфигурация для плагина Axios
   */
  constructor(config?: IAxiosPluginConfig) {
    this.instance = axios.create({
      baseURL: this.getApiUrl(),
      headers: {
        client: 'web',
        'Content-Type': 'application/json',
        ...(config?.headers || {}),
      },
    });

    // this.token = authService.getAccessToken() || null;

    // this.updateToken();

    this.setupResponseInterceptors();
  }

  /**
   * Получает API URL с учетом возможных конфигураций
   * @private
   * @returns  {string}  API URL
   */
  private getApiUrl(): string {
    return (
      (window as any).appSettings?.apiUrl ||
      process.env.VUE_APP_API_URL ||
      'https://api.sm-center.ru/water'
    );
  }

  /**
   * Настраивает перехватчики ответов для обработки успешных ответов и ошибок
   * @private
   */
  private setupResponseInterceptors(): void {
    this.instance.interceptors.request.use(
      (config) => {
        // задать тип для config
        config.headers['acx'] = authService.getAccessToken() || '';

        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        if (response.status !== 200 && response.data.Message) {
          this.handleErrorResponse(response);
        }
        return response;
      },
      (error: AxiosError<any>) => {
        this.handleRequestError(error);
        return Promise.reject(error);
      }
    );
  }

  /**
   * Обрабатывает ошибки в ответе, показывая уведомления или обработку ошибок
   * @private
   * @param    {AxiosResponse<any>}  response  Объект ответа Axios
   */
  private handleErrorResponse(response: AxiosResponse<any>): void {
    console.log('--- handleErrorResponse ---');
    console.log('response:', response); // временно

    // TODO: Show notification or handle the error response
  }

  /**
   * Обрабатывает ошибки запроса, включая истечение срока действия токена и перенаправление на страницу входа
   * @private
   * @param {AxiosError<any>} error - Объект ошибки Axios
   */
  private handleRequestError(error: AxiosError<any>): void {
    if (error.response && error.response.status === 401) {
      authService.resetAccessToken();
      authService.resetAccountInfo();
      console.error("Срок действия токена истек.");

      // if (process.env.NODE_ENV === 'production') {
        // const currentLocation = document.location.href;
        //   if (currentLocation && currentLocation !== '#/login') {
        //     document.location.replace(`${currentLocation.split('#')[0]}#/login`);
        //   }
        //   return;
        document.location.href = `#/login`;
      // }

      // const currentLocation = document.location.pathname;
      // if (currentLocation && currentLocation !== '/login') {
      //   document.location.replace(`${currentLocation.split('/')[0]}/login`);
      // console.error("Срок действия токена истек.");

      // const currentLocation = document.location.href;

      // if (currentLocation !== "/login") {
      //   document.location.replace(`${currentLocation.split("#")[0]}/#/login`);
      // }
    }
  }

  /**
   * Устанавливает базовый URL для экземпляра Axios
   * @param {string} baseURL - Базовый URL для установки
   */
  public setBaseURL(baseURL: string): void {
    this.instance.defaults.baseURL = baseURL;
  }

  /**
   * Устанавливает пользовательские заголовки для экземпляра Axios
   * @param {Record<string, string>} headers - Заголовки для установки
   */
  public setHeaders(headers: Record<string, string>): void {
    this.instance.defaults.headers = {
      ...this.instance.defaults.headers,
      ...headers,
    };
  }

  /**
   * Обновляет токен из localStorage и устанавливает его в заголовок запроса
   * @private
   */
  // private updateToken(): void {
  //   this.token = authService.getAccessToken() || null;
  //   this.instance.defaults.headers.common['acx'] = this.token;
  // }

  /**
   * Выполняет HTTP-запрос методом GET
   * @param    {string} url  URL для GET-запроса
   * @param    {AxiosRequestConfig} [config]  Опциональная конфигурация Axios запроса
   * @returns  {Promise<T>}  Обещание, разрешающееся данными из ответа
   */
  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.instance.get<T>(url, config);
      return response.data;
    } catch (error) {
      this.handleRequestError(error as AxiosError<any>);
      throw error;
    }
  }

  /**
   * Выполняет HTTP-запрос методом POST
   * @param    {string} url                   URL для POST-запроса
   * @param    {*} [data]                     Данные для включения в POST-запрос
   * @param    {AxiosRequestConfig} [config]  Опциональная конфигурация Axios запроса
   * @returns  {Promise<T>}                   Обещание, разрешающееся данными из ответа
   */
  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this.instance.post<T>(url, data, config);
      return response.data;
    } catch (error) {
      this.handleRequestError(error as AxiosError<any>);
      throw error;
    }
  }

  public async postForm<T>(
    url: string,
    formData?: FormData,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this.instance.post<T>(url, formData, {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      this.handleRequestError(error as AxiosError<any>);
      throw error;
    }
  }
}

/**
 * @const  {Http} http  Экземпляр класса Http для выполнения HTTP-запросов
 */
export const http = new Http();

// Интерфейс для типизации данных в ответе
// interface MyData {
//   id: number;
//   name: string;
// ...
// }

// Пример использования с базовыми настройками
// const example1 = async () => {
//   try {
//     const result = await http.get<MyData>('/api/data');
//     console.log(result);
//   } catch (error) {
//     console.error('Произошла ошибка:', error);
//   }
// }

// // Пример использования с добавлением заголовков
// const example2 = async () => {
//   try {
//     const headers = {
//       Authorization: 'Bearer myAccessToken',
//       'X-Custom-Header': 'custom-value',
//     };

//     const result = await http.get<MyData>('/api/data', { headers });
//     console.log(result);
//   } catch (error) {
//     console.error('Произошла ошибка:', error);
//   }
// }

// Пример использования с изменением базового URL и установкой заголовков
// const example3 = async () => {
//   try {
//     http.setBaseURL('https://api.example.com');
//     const commonHeaders = {
//       'X-Common-Header': 'common-value',
//     };
//     http.setHeaders(commonHeaders);

//     const postData = { key: 'value' };
//     const result = await http.post<MyData>('/api/post', postData);
//     console.log(result);
//   } catch (error) {
//     console.error('Произошла ошибка:', error);
//   }
// }

// Пример использования GET-запроса с параметрами
// const example4 = async () => {
//   try {
//     const params = {
//       page: 1,
//       limit: 10,
//     };

//     const result = await http.get<MyData>('/api/data', { params });
//     console.log(result);
//   } catch (error) {
//     console.error('Произошла ошибка:', error);
//   }
// }

// Пример использования POST-запроса для отправки файла
// async function exampleWithFile() {
//   try {
//     const formData = new FormData();
//     formData.append('file', file);

//     const result = await http.post<MyData>('/api/upload', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     console.log(result);
//   } catch (error) {
//     console.error('Произошла ошибка:', error);
//   }
// }
