export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "meters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["нет приборов учета"]), _normalize([_interpolate(_named("n")), " прибор учета"]), _normalize([_interpolate(_named("n")), " прибора учета"]), _normalize([_interpolate(_named("n")), " приборов учета"])])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуют передачи показаний"])}
      },
      "en": {
        "meters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no meters"]), _normalize([_interpolate(_named("n")), " meter"]), _normalize([_interpolate(_named("n")), " meters"])])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires transfer of readings"])}
      }
    }
  })
}
