import {
  ILogin,
  ISendCheckCodeFlashCall,
  ISendCheckCode,
  ICheckAccessCode,
  IRegisterByPhone,
  IRequestAccessCode,
} from '@/@types/api/auth';
import { ICommonResult } from '@/@types/api/common';
import { http } from '@/plugins/axios';

const endPoint = '/auth';

/**
 * Авторизация
 *
 * @callback ILogin Метод авторизации
 * @param {ILoginPayload} Парамтеры:
 * @param {string} phone Логин (номер телефона)
 * @param {string} password Пароль
 * @returns {Promise<AxiosResponse<ILoginResult>>} Промис с данными авторизовавшегося пользователя
 *
 */
const login: ILogin = (payload) => {
  return http.post(`${endPoint}/login`, payload);
};

/**
 * Запрос проверочного кода по звонку
 *
 * @callback ISendCheckCodeFlashCall Метод запроса проверочного кода по звонку
 * @param {ISendCheckCodeFlashCallPayload} Параметры:
 * @param {string} phone Логин (номер телефона)
 * @param {string} dontCheck Если true, то код отправляется без проверки таймаута отправки
 * @returns {Promise<AxiosResponse<ICommonResult>>} Промис с результатом запроса в виде CommonResult
 *
 */
const sendCheckCodeFlashCall: ISendCheckCodeFlashCall = (payload) => {
  return http.post(`${endPoint}/sendCheckCodeFlashCall`, payload);
};

/**
 * Запрос проверочного кода по смс
 *
 * @callback ISendCheckCode Метод запроса проверочного кода по смс
 * @param {ISendCheckCodePayload} Параметры:
 * @param {string} phone Логин (номер телефона)
 * @param {string} dontCheck Если true, то код отправляется без проверки таймаута отправки
 * @returns {Promise<AxiosResponse<ICommonResult>>} Промис с результатом запроса в виде CommonResult
 *
 */
const sendCheckCode: ISendCheckCode = (payload) => {
  return http.post(`${endPoint}/sendCheckCode`, payload);
};

/**
 * Проверка кода
 *
 * @callback ICheckAccessCode Метод проверки кода
 * @param {ICheckAccessCodePayload} Параметры:
 * @param {string} phone Логин (номер телефона)
 * @param {string} code Проверочный код
 * @returns {Promise<AxiosResponse<ICheckAccessCodeResult>>} Промис с объектом, содержащий параметр IsCorrect - true-проверка пройдена, false-нет
 *
 */
const checkAccessCode: ICheckAccessCode = (payload) => {
  return http.post(`${endPoint}/checkAccessCode`, payload);
};

/**
 * Регистрация по номеру телефона
 *
 * @callback IRegisterByPhone Метод регистрации по номеру телефона
 * @param {IRegisterByPhonePayload} Параметры:
 * @param {string} fio ФИО
 * @param {string} phone Логин (номер телефона)
 * @param {string} password Пароль
 * @param {string} code Проверочный код
 * @param {string} birthday Дата рождения
 * @returns {Promise<AxiosResponse<ICommonResult>>} Промис с результатом запроса в виде CommonResult
 *
 */
const registerByPhone: IRegisterByPhone = (payload) => {
  return http.post(`${endPoint}/registerByPhone`, payload);
};

/**
 * Запрос кода доступа для регистрации пользователя.
 * @param {IRequestAccessCode} payload Параметры:
 * @param {Phone} phone Номер телефона 
 * @returns {Promise<ICommonResult>}
 */
const sendCheckCodeByVoiceCall = (payload: IRequestAccessCode): Promise<ICommonResult> => {
  return http.post(`${endPoint}/SendCheckCodeByVoiceCall`, payload);
}

export default {
  login,
  sendCheckCodeFlashCall,
  sendCheckCode,
  checkAccessCode,
  registerByPhone,
  sendCheckCodeByVoiceCall,
};
