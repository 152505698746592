import {
  IUpdateProfile,
  IDeleteAccountByIdent,
  IGetGuestMobileUsers,
  IAddGuestMobileUser,
  IDeleteGuestMobileUser,
  IDeleteMobileUser,
  IAddAccountByIdent,
  IGetClientBases,
  IGetAccountPrefixes,
  IChangePassword,
} from '@/@types/api/user';
import userApi from '@/api/user';
import { useStore } from 'vuex';

export function useUser() {
  const $store = useStore();

  // FETCH DATA ----------------------------------------------------------------

  const updateProfile: IUpdateProfile = async (params) => {
    return await userApi.updateProfile(params);
  };

  const deleteAccountByIdent: IDeleteAccountByIdent = async (params) => {
    return await userApi.deleteAccountByIdent(params);
  };

  const getGuestMobileUsers: IGetGuestMobileUsers = async () => {
    return await userApi.getGuestMobileUsers().then((data) => {
      $store.commit('user/SET_GUEST_MOBILE_USERS_LIST', data);

      return data;
    });
  };

  const addGuestMobileUser: IAddGuestMobileUser = async (payload) => {
    return await userApi.addGuestMobileUser(payload);
  };

  const deleteGuestMobileUser: IDeleteGuestMobileUser = async (payload) => {
    return await userApi.deleteGuestMobileUser(payload);
  };

  const deleteMobileUser: IDeleteMobileUser = async () => {
    return await userApi.deleteMobileUser();
  };

  /**
   *
   * @param payload
   * @returns Результат запроса с данными аккаунта
   */
  const addAccountByIdent: IAddAccountByIdent = async (payload) => {
    return await userApi.addAccountByIdent(payload);
  };

  /**
   * Получение списка внешних баз клиента
   * @returns  Результат запроса со списком баз
   */
  const getClientBases: IGetClientBases = async () => {
    return await userApi.getClientBases().then((data) => {
      $store.commit('user/SET_CLIENT_BASES', data);

      return data;
    });
  };

  /**
   * Получение списка организаций и используемых префиксов лицевых счетов.Список не пуст в том случае, если в БД настроены алиасы для разных организаций, но обращение идет не через алиас, а через основную базу.
   * @returns  Результат запроса со списком организаций и используемых префиксов лицевых счетов
   */

  const getAccountPrefixes: IGetAccountPrefixes = async () => {
    return await userApi.getAccountPrefixes().then((data) => {
      $store.commit('user/SET_ACCOUNT_PREFIXES', data);

      return data;
    });
  };

  const changePassword: IChangePassword = async (payload) => {
    return await userApi.changePassword(payload);
  };

  return {
    updateProfile,
    deleteAccountByIdent,
    getGuestMobileUsers,
    addGuestMobileUser,
    deleteGuestMobileUser,
    deleteMobileUser,
    addAccountByIdent,
    getClientBases,
    getAccountPrefixes,
    changePassword,
  };
}
