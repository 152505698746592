import { ICommonResult, INamedValue } from '@/@types/api/common'
import { 
  IEventBlockData,
  INewsInfo
} from "@/@types/api/common";
import { http } from "@/plugins/axios";

const endPoint = '/common'

/**
 * Возвращает данные для блока события мобильного приложения: новости, 
 * объявления, опросы, доп. услуги.
 * 
 * @param {boolean} htmlAsPlaintText если true, то поля ShortContent в News и 
 *                                    Text в Announcements из html 
 *                                    конвертируются в plain text (по умолчанию 
 *                                    false).
 * @returns {Promise<IEventBlockData>}
 */
const eventBlockData = (htmlAsPlaintText?: boolean): Promise<IEventBlockData> => {
  return http.get(`${endPoint}/EventBlockData`, {
    params: { htmlAsPlaintText }
  });
}

/**
 * Возвращает все новости
 * 
 * @returns {Promise<INewsInfo[]>}
 */
const allNews = (): Promise<INewsInfo[]> => {
  return http.get(`${endPoint}/AllNews`)
}

/**
 * Метод, который устанавливает что новость прочитана
 * 
 * @param {number} id ID новости
 * @returns {Promise<ICommonResult>}
 */
const setNewsReadedFlag = (id: number): Promise<ICommonResult> => {
  return http.post(`${endPoint}/SetNewsReadedFlag`, id)
}

/**
 * Метод который отправляет СМС по указанному номер со ссылкой на ЛК
 * 
 * @param {string} phone номер телефона
 * @returns {Promise<ICommonResult>}
 */
const sendSmsToClintWithAppLink = (phone: string): Promise<ICommonResult> => {
  return http.post(`${endPoint}/SendSmsToClintWithAppLink`, phone)
}

/**
 * Метод возвращает по указанному коду ссылку и сохраняет информацию о клике.
 * 
 * @returns {Promise<string>} url ссылки
 */
const clickableLink = (code: number): Promise<string> => {
  return http.post(`${endPoint}/ClickableLink`, code)
}

/**
 * Справочник типов транспортных средств
 * 
 * @returns {Promise<INamedValue>}
 */
const vehicleTypes = (): Promise<INamedValue> => {
  return http.get(`${endPoint}/VehicleTypes`)
}

export default {
  eventBlockData,
  allNews,
  setNewsReadedFlag,
  sendSmsToClintWithAppLink,
  clickableLink,
  vehicleTypes
}