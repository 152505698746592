import { ActionTree } from 'vuex';
import billsApi from '@/api/bills'
import { downloadBlob } from "@/utils/download";
import { IState } from './requests';
import type {
  IDownloadInvoiceFile
} from '@/@types/bills'

export default {
  actions: <ActionTree<IState, any>> {
    async downloadInvoice (
      _,
      {
        id,
        name,
      }: IDownloadInvoiceFile
    ): Promise<void> {
      return await billsApi.download({
          id,
        })
          .then((blob) => {   
            downloadBlob(blob, name || id.toString())
          }).catch((error) => {
            throw Error(`Ошибка скачивания квитанции: ${error}`);
          })
      }
  }
}
