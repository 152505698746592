export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
      },
      "en": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
      }
    }
  })
}
