import { getCookie, setCookie } from '@/plugins/cookie';

import { GetterTree, MutationTree, ActionTree } from "vuex"
import { DeviceId, IGetMessagePayload, IGetFilePayload } from "@/@types/api/techSupport";

import techSupportApi from "@/api/techSupport";

type UpdateKey = number | null

interface IState {
  updateKey: UpdateKey,
  deviceId: DeviceId,
  shouldStartNewIssue: boolean,
}

export default {
  state: (): IState => ({
    updateKey: null,
    deviceId: null,
    shouldStartNewIssue: false,
  }),

  getters: <GetterTree<IState, any>>{
    getDeviceId: (state) => {
      if (state.deviceId) {
        return state.deviceId;
      }
      
      return getCookie("deviceId") || null;
    },
  },

  mutations: <MutationTree<IState>>{
    SET_DEVICE_ID(state, data: DeviceId) {
      state.deviceId = data;
    },

    SET_UPDATE_KEY (state, data: number) {
      state.updateKey = data;
    },

    SET_SHOULD_START_NEW_ISSUE (state, data: boolean) {
      state.shouldStartNewIssue = data;
    }
  },

  actions: <ActionTree<IState, any>>{
    async registerNewDevice({ commit }) {
      return await techSupportApi
        .registerNewDevice()
        .then((data) => {
          commit('SET_DEVICE_ID', data);

          const date = new Date();
          setCookie("deviceId", data as string, { expires: date.setDate(date.getDate() + 1) });

          return data;
      });
    }, 

    async fetchMessages({ commit, getters, dispatch }) {
      const payload: Record<PropertyKey, any> = {};

      try {
        payload.deviceId = getters.getDeviceId || await dispatch('registerNewDevice');
        
        payload.info = window.navigator.userAgent;

        const data = await techSupportApi.getMessages(payload as IGetMessagePayload);

        if (data.UpdateKey) {
          commit('SET_UPDATE_KEY', data.UpdateKey);
        }
        commit('SET_SHOULD_START_NEW_ISSUE', data.ShouldStartNewIssue);

        return data;

      } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
      }
    },

    async fetchFile({ getters, dispatch }, fileId: number) {
      const payload: Record<PropertyKey, any> = {};

      try {
        payload.id = fileId;

        payload.deviceId = getters.getDeviceId || await dispatch('registerNewDevice');

        const data = await techSupportApi.getFile(payload as IGetFilePayload);

        return data;
      } catch (error) {
        console.error('Error fetching file:', error);
        throw error;
      }
    },

    async getSupportHelpTree() {
      return await techSupportApi.getSupportHelpTree();
    }
  }
}
