import {
  IAccountAccountingInfo,
  ISumWithComission,
} from '@/@types/api/accounting';

import { useStore } from 'vuex';

export function useAccounting() {
  const $store = useStore();

  // FETCH DATA ----------------------------------------------------------------

  // FIXME: Удалить. Использовать экшен
  const fetchInfo = (): Promise<IAccountAccountingInfo> => {
    return $store.dispatch('accounting/fetchInfo')
      .then((response) => response)
  };

  const fetchSumWithComission = (sum: number): Promise<ISumWithComission> => {
    return $store.dispatch('accounting/fetchSumWithComission', sum)
      .then((response) => response)
  };

  return {
    fetchInfo,
    fetchSumWithComission,
  };
}
