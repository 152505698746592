import { ActionTree } from "vuex";
import emergencyWorksApi from '@/api/emergencyWorks';

export default {
  actions: <ActionTree<any, any>>{
    getList() {
      return emergencyWorksApi
        .getList()
        .then((response) => response || []);
    }
  }
}