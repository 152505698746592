import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import * as authService from '@/utils/authService';
import authApi from '@/api/auth';
import { AxiosResponse } from 'axios';

import {
  ILoginResult,
  ILoginPayload,
  ISendCheckCodeFlashCallPayload,
  ISendCheckCodePayload,
  ICheckAccessCodePayload,
  IRegisterByPhonePayload,
  ICheckAccessCodeResult,
  IRequestAccessCode
} from '@/@types/api/auth';
import { ICommonResult } from '@/@types/api/common';

export default {
  // state: (): any => ({}),

  getters: <GetterTree<any, any>>{},

  mutations: <MutationTree<any>>{},

  actions: <ActionTree<any, any>>{
    /**
     * Вход на форме авторизации
     * @param {ActionContext<any, any>} контекст
     * @param {ILoginPayload} Параметры запроса
     * @returns {Promise<ILoginResult>} Промис с данными авторизовавшегося пользователя
     */
    async login(
      { commit }: ActionContext<any, any>,
      payload: ILoginPayload
    ): Promise<ILoginResult> {
      try {
        const result = await authApi.login(payload);
        const { acx } = result;
        if (acx) {
          authService.setAccessToken(acx);
          commit('user/SET_USER_DATA', result, { root: true });
        }
        return result;
      } catch (error) {
        console.log('Произошла ошибка:', error);
        throw error;
      }
    },

    logout() {
      authService.resetAccessToken();
      authService.resetAccountInfo();
      location.reload()
    },

    /**
     * Запрос проверочного кода по звонку
     * @param {ActionContext<any, any>} контекст
     * @param {ISendCheckCodeFlashCallPayload} Параметры запроса
     * @returns {Promise<AxiosResponse<ICommonResult>>} Промис с результатом запроса в виде CommonResult
     */
    async sendCheckCodeFlashCall(
      _,
      payload: ISendCheckCodeFlashCallPayload
    ): Promise<AxiosResponse<ICommonResult>> {
      try {
        const result = await authApi.sendCheckCodeFlashCall(payload);
        return result;
      } catch (error) {
        console.log('Произошла ошибка:', error);
        throw error;
      }
    },

    /**
     * Запрос проверочного кода по SMS
     * @param {ActionContext<any, any>} контекст
     * @param {ISendCheckCodePayload} Параметры запроса
     * @returns {Promise<AxiosResponse<ICommonResult>>} Промис с результатом запроса в виде CommonResult
     */
    async sendCheckCode(
      _,
      payload: ISendCheckCodePayload
    ): Promise<AxiosResponse<ICommonResult>> {
      try {
        const result = await authApi.sendCheckCode(payload);
        return result;
      } catch (error) {
        console.log('Произошла ошибка:', error);
        throw error;
      }
    },

    /**
     * Проверка кода
     * @param {ActionContext<any, any>} контекст
     * @param {ICheckAccessCodePayload} Параметры запроса
     * @returns {Promise<AxiosResponse<ICheckAccessCodeResult>>} Промис с объектом, содержащий параметр IsCorrect - true-проверка пройдена, false-нет
     */
    async checkAccessCode(
      _,
      payload: ICheckAccessCodePayload
    ): Promise<AxiosResponse<ICheckAccessCodeResult>> {
      try {
        const result = await authApi.checkAccessCode(payload);
        return result;
      } catch (error) {
        console.log('Произошла ошибка:', error);
        throw error;
      }
    },

    /**
     * @callback IRegisterByPhone Регистрация по номеру телефона
     * @param {ActionContext<any, any>} контекст
     * @param {IRegisterByPhonePayload} Параметры запроса
     * @returns {Promise<AxiosResponse<ICommonResult>>} Промис с результатом запроса в виде CommonResult
     */
    async registerByPhone(
      _,
      payload: IRegisterByPhonePayload
    ): Promise<AxiosResponse<ICommonResult>> {
      try {
        const result = await authApi.registerByPhone(payload);
        return result;
      } catch (error) {
        console.log('Произошла ошибка:', error);
        throw error;
      }
    },

    /**
   * Получение кода доступа по телефону. Звонит робот и диктует числа
   * @param {IRequestAccessCode} payload Параметры:
   * @param {Phone} phone Номер телефона
   * @returns {Promise<ICommonResult>}
   */
    async sendCheckCodeByVoiceCall(_, payload: IRequestAccessCode): Promise<ICommonResult> {
      return await authApi
        .sendCheckCodeByVoiceCall(payload)
        .then((result) => {
          return result
        })
    }
  },
};
