import { ActionTree } from 'vuex';

import type {
  IGetPayLinkPayload,
  IGetPayLinkResponse,
  IPaymentSystem,
} from '@/@types/api/payOnline';
import type { ICommonResult } from '@/@types/api/common';
import payOnlineApi from '@/api/payOnline';

export default {
  state: () => ({}),

  actions: <ActionTree<any, any>>{
    async getPayLink(
      ctx,
      payload: IGetPayLinkPayload,
    ): Promise<IGetPayLinkResponse> {
      const response = (await payOnlineApi.getPayLink(payload)) as
        | IGetPayLinkResponse
        | ICommonResult;

      if ('isSucceed' in response && !response.isSucceed) {
        throw new Error((response as ICommonResult).error);
      }

      return response as IGetPayLinkResponse;
    },

    async getPaymentSystemsList(
      ctx,
      accountId: number | undefined = undefined,
    ): Promise<IPaymentSystem[]> {
      return await payOnlineApi.getPaymentSystemsList(accountId);
    },
  },
};
