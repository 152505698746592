import cssColorVariables from '@/assets/cssColorVariables';

import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  IAppSetting,
  IMenuItem,
  IAppMenu,
  ISearchMenuItem,
  IAppLink,
} from '@/@types/config';

import i18n from '@/plugins/i18n';
import configApi from '@/api/config';

const i18nInstance = i18n.global;

interface IState {
  appSettings: IAppSetting | null;
}

/** Это название цветов, которые приходят в настройки приложения по АПИ  */
const customColorsKeys: string[] = [
  'PrimaryInverse',
  'Accent',
  'ElmHover',
  'Brand1',
  'Brand2',
  'Brand3',
  'Brand4',
  'Skin',
  'SignError',
  'SignSuccess',
  'OnSign',
  'Back',
  'Back2',

  'Attention1',
  'Attention2',
  'SubAttention1',
  'SubAttention2',
  'SubAttention3',
  'SubAttention4',
  'SubAttention5',
  'SurfaceIllustration',

  'Title',
  'OnSurfaceTitle',
  'Body',
  'OnSurfaceBody',
  'Label',
  'OnSurfaceLabel',
  'Additional',
  'OnSurfaceAdditional',
  'Disabled',
  'OnSurfaceDisabled',
  'BodyInverse',
  'OnSurfaceBodyInverse'
];

export default {
  state: (): IState => ({
    appSettings: null,
  }),

  getters: <GetterTree<IState, any>>{
    getMenu({ appSettings }: IState): IMenuItem[] {
      let menu: IMenuItem[] = [];

      const items: ISearchMenuItem[] = [
        {
          searchTitle: 'Оплата ЖКУ',
          title: i18nInstance.t('menu_payment'),
          route: { name: 'Payment' },
          order: 1,
        },
        {
          searchTitle: 'Заявки',
          title: i18nInstance.t('menu_requests'),
          route: { name: 'Requests', query: { tab: 0 } },
          order: 2,
        },
        {
          searchTitle: ['Показания счетчиков', 'Показания приборов'],
          title: i18nInstance.t('menu_meters'),
          route: { name: 'Meters' },
          order: 3,
        },
        // {
        //   searchTitle: 'Опросы',
        //   title: i18nInstance.t('menu_polls'),
        //   routeName: 'Polls',
        //   order: 4
        // },
        // {
        //   searchTitle: 'Квитанции',
        //   title: i18nInstance.t('menu_bills'),
        //   routeName: 'Bills',
        //   order: 5
        // },
      ];

      appSettings?.menu.map((item: IAppMenu): void => {
        const searchedItem = items.find((x) => {
          if (Array.isArray(x.searchTitle)) {
            return x.searchTitle.includes(item.name_app) && item.visible;
          }

          return x.searchTitle === item.name_app && item.visible;
        });

        if (searchedItem) {
          menu = [
            ...menu,
            {
              title: searchedItem.title,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: Unreachable code error
              route: { name: searchedItem.route.name, query: searchedItem.route.query },
              order: searchedItem.order,
            },
          ];
        }
      }) || [];

      if (appSettings?.showOurService) {
        menu = [
          ...menu,
          {
            title: i18nInstance.t('menu_services'),
            route: { name: 'Services' },
            order: 6,
          },
        ];
      }

      // TODO: Добавить учиываение настройки показывать пункт "События"
      // Она берется из настроек пользователя

      menu = [
        ...menu,
        {
          title: i18nInstance.t('menu_home'),
          route: { name: 'Home' },
          order: 0,
        },
      ];

      return menu.sort((a, b) => {
        return a.order - b.order;
      });
    },

    getAppSettings({ appSettings }: IState): IAppSetting | null {
      return appSettings;
    },

    getAppTheme({ appSettings }: IState): string {
      return appSettings?.appTheme || 'light';
    },

    getColors({ appSettings }: IState): Record<PropertyKey, string | null> | null {
      if (!appSettings) return null;

      const appTheme = appSettings.appTheme || 'light';
      const colors: Record<PropertyKey, any> = {};

      customColorsKeys.map((key): void => {
        const customColor = (appSettings as Record<PropertyKey, any>)[key];
        colors[key] = customColor
          ? customColor
          : (cssColorVariables as Record<PropertyKey, any>)[appTheme][key]
          || null;
      });
      
      return colors;
    },

    getAppsLinks({ appSettings }: IState): IAppLink[] {
      return [
        {
          name: 'GooglePlay',
          icon: 'GooglePlay',
          href: appSettings?.appLinkAndroid || '#',
        },
        {
          name: 'AppStore',
          icon: 'AppStore',
          href: appSettings?.appLinkIOS || '#',
        },
      ];
    },

    getStartView({ appSettings }: IState): string {
      switch (appSettings?.startScreen) {
        case 'События':
          return 'Home';
        case 'Оплата':
          return 'Payment';
        case 'Показания':
          return 'Meters';
        case 'Наши услуги':
          return 'Services';
        case 'Главная':
          return 'Home';
        case 'Заявки':
          return 'Requests';
        default:
          return 'Home';
      }
    },

    getLanguage({ appSettings }: IState): string {
      return appSettings && appSettings.language ? appSettings.language : 'ru';
    },
  },

  mutations: <MutationTree<IState>>{
    SET_APP_SETTINGS(state, data: IAppSetting) {
      state.appSettings = data;
      // state.appTheme = data.appTheme;

      const { language } = data;

      if (!localStorage.getItem('language')) {
        localStorage.setItem('language', language);
      }
    },
  },

  actions: <ActionTree<IState, any>>{
    async fetchAppSettings({ commit }): Promise<IAppSetting> {
      return await configApi
        .getMobileAppSettings()
        .then((result) => {
          commit('SET_APP_SETTINGS', result);

          return result.data;
        });
    },

  },
};
