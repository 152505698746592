import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';

import type {
  IMeterInfo,
  ISaveMeterValuePayload,
  ISetMeterCustomNamePayload,
} from '@/@types/api/meters';
import {
  VERIFICATION_STATUS_KEY,
  BLOCKED_STATUS_KEY,
  TRANSFER_STATUS_KEY,
} from '@/@types/api/meters';
import metersApi from '@/api/meters';

interface IState {
  meters: IMeterInfo[] | [];
}

export default {
  state: (): IState => ({
    meters: [],
  }),

  getters: <GetterTree<IState, any>>{
    getPendingMeters: (state) =>
      // state.meters?.filter(
      //   (meter) => meter.ValuesCanAdd && !meter.AutoValueGettingOnly,
      // ) || [],
      // state.meters?.filter(
      //   (meter) => {
      //     console.log('meter :>> ', meter)
      //     console.log('meter 2 :>> ', meter.ValuesCanAdd && !meter.AutoValueGettingOnly)
      //     meter.ValuesCanAdd && !meter.AutoValueGettingOnly
      //   }
      // ) || [],
      state.meters?.filter((meter) => (
        meter.ValuesCanAdd &&
        !meter.AutoValueGettingOnly &&
        meter.statusCode === TRANSFER_STATUS_KEY
      )) || [],

    getExpiredVerificationMeters: (state) => {
      if (state.meters) {
        return state.meters.filter((meter) =>
          [VERIFICATION_STATUS_KEY, BLOCKED_STATUS_KEY].includes(
            meter.statusCode,
          ),
        );
      }

      return [];
    },
  },

  mutations: <MutationTree<IState>>{
    SET_METERS(state, data: IMeterInfo[]) {
      state.meters = data;
    },
  },

  actions: <ActionTree<IState, any>>{
    setMeters({ commit }: ActionContext<IState, IState>, data: IMeterInfo) {
      commit('SET_METERS', data);
    },

    async saveMeterValue(ctx, payload: ISaveMeterValuePayload) {
      const result = await metersApi.saveMeterValue(payload);

      if (!result.isSucceed) {
        throw new Error(result.error);
      }
    },

    async fetchList({ commit }) {
      return await metersApi
        .list()
        .then((response) => {
          commit('SET_METERS', response);

          return response;
        });
    },

    async saveCustomName(ctx, payload: ISetMeterCustomNamePayload) {
      const result = await metersApi.setMeterCustomName(payload);

      if ('isSucceed' in result && !result.isSucceed) {
        throw new Error(result.error);
      }
    },

    async createRequestOnMeterRecheck(ctx, meterId: number) {
      return await metersApi.createRequestOnMeterRecheck(meterId);
    },
  },
};
