import { Ref, ref } from "vue";

interface IUseAddIdentReturns {
  showAddIdentModal: Ref<boolean>,
  handleAddNewIdent: () => void,
}

export function useAddIdent() {
  const showAddIdentModal = ref<boolean>(false);

  const handleAddNewIdent = (): void => {
    showAddIdentModal.value = true
  }

  return <IUseAddIdentReturns> {
    showAddIdentModal,
    handleAddNewIdent,
  }
}
