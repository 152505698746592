/**
 * Сохраняет Blob в виде файла с заданным именем
 * 
 * @param  {Blob}    blob   Объект Blob, представляющий данные файла
 * @param  {string}  name   Полное название файла с расширением
 */

export const downloadBlob = (blob: Blob, name: string) => {
  // Преобразует Blob в URL Blob (специальный URL, указывающий на объект в памяти браузера)
  const blobUrl: string = URL.createObjectURL(blob);

  // Создает элемент ссылки
  const link: HTMLAnchorElement = document.createElement("a");

  // Устанавливает свойства ссылки для указания на URL Blob и имени файла
  link.href = blobUrl;
  link.download = name;

  // Добавляет ссылку к body документа
  document.body.appendChild(link);

  // Имитирует событие клика на ссылке
  // Это необходимо, так как link.click() не работает в последних версиях Firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Удаляет ссылку из body документа
  document.body.removeChild(link);
};
