import type {
  IGetPayLinkPayload,
  IGetPayLinkResponse,
  IGetPaymentParametersPayload,
  IGetPaymentParametersResponse,
  IPaymentSystem,
} from '@/@types/api/payOnline';
import type { ICommonResult } from '@/@types/api/common';

import { http } from '@/plugins/axios';

const API_ENDPOINT = 'PayOnline';

function getEndpointPath(additionalPath: string) {
  return `${API_ENDPOINT}/${additionalPath}`;
}

async function getPayLink(
  payload: IGetPayLinkPayload,
): Promise<IGetPayLinkResponse | ICommonResult> {
  return http.post(getEndpointPath('GetPayLink'), payload);
}

async function getPaymentParameters(
  payload: IGetPaymentParametersPayload,
): Promise<IGetPaymentParametersResponse> {
  return http.post(getEndpointPath('GetPaymentParameters'), payload);
}

async function getPaymentSystemsList(
  accountID: number | undefined = undefined,
): Promise<IPaymentSystem[]> {
  if (!accountID) {
    return await http.get(getEndpointPath('GetPaymentSystemsList'));
  }

  return await http.get(getEndpointPath('GetPaymentSystemsList'), {
    params: { accountID },
  });
}

export default {
  getPayLink,
  getPaymentParameters,
  getPaymentSystemsList,
};
