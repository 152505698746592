import { ActionContext, GetterTree, MutationTree, ActionTree } from 'vuex';
import { ILoginResult, IAccountInfo } from '@/@types/api/auth';
import {
  IGuestMobileUsersInfo,
  IAccountPrefixesResult,
} from '@/@types/api/user';
import { IState } from '@/@types/user'
import { INamedValue } from '@/@types/api/common';

import userApi from '@/api/user';

export default {
  state: (): IState => ({
    guestMobileUsers: null,
    clientBases: [],
    accountPrefixes: [],
    user: null,
  }),

  getters: <GetterTree<IState, any>>{
    getAccountPrefixes: ({
      accountPrefixes,
    }: IState): IAccountPrefixesResult[] => accountPrefixes,

    getClientBases: ({ clientBases }: IState): INamedValue[] => clientBases,

    getGuestMobileUsersList({ guestMobileUsers }: IState) {
      return guestMobileUsers?.Data || [];
    },

    isAuth: (_, getters: any): boolean => {
      const user = getters.getUser

      return !!(user && user?.email && user?.email.length > 0);
    },

    getUser: ({ user }: IState): ILoginResult | null => {
      const lsUser = localStorage.getItem('account/user')
      const parsedUser = lsUser ? JSON.parse(lsUser) : null

      return user ? user : parsedUser;
    },

    getAccountSettings: (_, getters: any) =>
      (accountId: number): IAccountInfo | null => {
        const user = getters.getUser;
        if (!user || !user.accounts) return null;

        const account = user.accounts.find((account: IAccountInfo) => account.id === accountId);

        return account || null;
      }, 

    // getAccounts: ({ userInfo }: IState) => userInfo.Accounts || [],
  },

  mutations: <MutationTree<IState>>{
    SET_GUEST_MOBILE_USERS_LIST(state, data: IGuestMobileUsersInfo) {
      state.guestMobileUsers = data;
    },

    SET_CLIENT_BASES(state, data: INamedValue[]) {
      state.clientBases = data;
    },

    SET_ACCOUNT_PREFIXES(state, data: IAccountPrefixesResult[]) {
      state.accountPrefixes = data;
    },

    SET_USER_DATA(state, data: ILoginResult) {
      state.user = data;

      localStorage.setItem('account/user', JSON.stringify(data))
    },

    SET_USER_INFO(state, data) {
      state.user = data;

      // TODO:
      localStorage.setItem('user/info', JSON.stringify(data));
    }
  },

  actions: <ActionTree<IState, any>>{
    getUserInfo({ commit }: ActionContext<any, any>) {
      return userApi
        .userInfo()
        .then((response: any) => {
          commit('SET_USER_INFO', response?.data);

          return response;
        });
    }
  }
};
