import { createApp } from 'vue';
import App from '@/App.vue';
import { router } from '@/router';
import store from '@/store';

import '@/assets/scss/index.scss';
import 'v-calendar/style.css';

import i18n from '@/plugins/i18n';
import notifications from '@/plugins/notifications';
import dayjs from '@/plugins/dayjs';
import globalBus from '@/plugins/globalBusPlugin';
import cookiePlugin from '@/plugins/cookie/cookiePlugin';
import { ClickOutsideDirectivePlugin } from '@/directives/v-click-outside'

import { setupCalendar } from 'v-calendar';

createApp(App)
  .use(store)
  .use(router)
  .use(notifications)
  .use(i18n)
  .use(dayjs)
  .use(globalBus)
  .use(cookiePlugin)
  .use(ClickOutsideDirectivePlugin)
  .use(setupCalendar, {})
  .mount('#app');
