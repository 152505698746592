// import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { IAnnouncementInfo } from '@/@types/api/common';
import { IEventBlockData } from "@/@types/api/common"
import commonApi from '@/api/common'

interface IState {
  announcements: IAnnouncementInfo[] | []
}

export default {
  state: (): IState => ({
    announcements: []
  }),

  getters: <GetterTree<IState, any>>{
    // getAnnounce: (state) => (id: number) => (
    //   state.announcements.find((announce) => (
    //     announce.ID === id
    //   ))
    // )
  },

  mutations: <MutationTree<IState>>{
    // SET_ANNOUCEMENTS(state, data: IAnnouncementInfo[]) {
    //   state.announcements = data
    // }
  },

  actions: <ActionTree<IState, any>>{
    // setAnnouncements({ commit }: ActionContext<IState, IState>, data: IAnnouncementInfo[]) {
    //   commit('SET_ANNOUCEMENTS', data)
    // }
    fetchEventBlockData(_, htmlAsPlaintText = <boolean>false): Promise<IEventBlockData> {
      return commonApi.eventBlockData(htmlAsPlaintText)
    }
  },
}