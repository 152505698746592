import i18n from '@/plugins/i18n';

const { t } = i18n.global;

// Payload

export interface ISaveMeterValuePayload {
  // ID прибора учета
  MeterId: number;
  // значение показаний (Т1 для многотарифного ПУ)
  Value: number;
  // значение Т2 2х и 3х тарифного ПУ (для однотарифного не заполняется)
  ValueT2?: number;
  // значение Т3 для 3х тарифного ПУ (для 1 – 2х тарифных не заполняется)
  ValueT3?: number;
}
export interface ISetMeterCustomNamePayload {
  // уникальный номер прибора
  MeterUniqueNumber: number;
  // произвольное имя прибора
  CustomName: string;
}

// Results

export interface IMeterValueInfo {
  // Дата
  Period: string;
  // true, если эта запись - показания по текущему периоду
  IsCurrentPeriod: boolean;
  // Значение (Т1 для 2х 3х тарифных)
  Value: number;
  // Значение Т2
  ValueT2: number;
  // Значение Т3
  ValueT3: number;

  IsAccepted: boolean;
  Kind: string;
}

export interface IMeter {
  ID: number;
  Name: string;
  CustomName: string | null;

  // Номер лицевого счета
  Ident: string;
  // Заводской номер
  FactoryNumber: string;
  // Дата последней поверки
  LastCheckupDate: string;
  // Межповерочный интервал
  RecheckInterval: number;
  // Дата следующей поверки
  NextCheckupDate: string;
  // Ресурс (Холодная вода, Горячая вода, Электрическая энергия, Газ, Тепловая энергия,
  //         Бытовой газ в баллонах, Твердое топливо, Сточные воды)
  Resource: string;
  // Адрес
  Address: string;
  // Уникальный номер
  UniqueNum: string;
  TariffNumber: string;
  // число тарифов с типом int
  TariffNumberInt: 1 | 2 | 3;
  // Количество знаков после запятой
  NumberOfDecimalPlaces: number;
  // Количество знаков целой части
  NumberOfIntegerPart: number;
  // отключен
  IsDisabled: boolean;
  // ед измерения
  Units: string;
  ValuesStartDay: number;
  ValuesEndDay: number;
  ValuesCanAdd: boolean;
  // Если ValuesCanAdd=false - то код ошибки, поясняющий причину недоступности передачи параметров
  // см. раздел список кодов ошибок
  ValueCanAddErrorCode: string;
  ValuesPeriodStartIsCurrent: boolean;
  ValuesPeriodEndIsCurrent: boolean;
  // Показания
  Values: IMeterValueInfo[];
  // флаг "Автосъем показаний"
  AutoValueGettingOnly: boolean;
  // текстовое сообщение о сроках передачи показаний
  PeriodMessage: string;
  // Название тарифа 1
  Tariff1Name: string;
  // Название тарифа 2
  Tariff2Name: string;
  // Название тарифа 3
  Tariff3Name: string;
  // Дата запуска
  StartDate: string;
  // Начальное значение по 1 тарифу, если указано
  StartValue?: number;
  // Начальное значение по 2 тарифу, если указано
  StartValueT2?: number;
  // Начальное значение по 3 тарифу, если указано
  StartValueT3?: number;
  // Показания, по которым будут проверяться вводимые показания
  CheckValue?: number;
  CheckValueT2?: number;
  CheckValueT3?: number;
  // Разрешено ли у прибора делать поверку
  AllowMeterVerificationRequest: boolean;
  ExistVerificationRequest: boolean;
}

export const BASE_METER_STATE: IMeterValueInfo = {
  Period: '',
  IsCurrentPeriod: true,
  Value: 0,
  ValueT2: 0,
  ValueT3: 0,
  IsAccepted: false,
  Kind: 'string',
};

export const TRANSFER_STATUS_KEY = 'transfer';
export const VERIFICATION_STATUS_KEY = 'verification';
export const TRANSFERED_STATUS_KEY = 'transfered';
export const CLOSED_STATUS_KEY = 'closed';
export const BLOCKED_STATUS_KEY = 'blocked';
export const DISABLED_STATUS_KEY = 'disabled';

// следующее преобразование типов из-за tsc ошибки https://github.com/microsoft/TypeScript/issues/40793
const TRANSFER_STATUS_VALUE = t('meter_status_transfer') as unknown as number;
const VERIFICATION_STATUS_VALUE = t(
  'meter_status_verification',
) as unknown as number;
const TRANSFERED_STATUS_VALUE = t(
  'meter_status_transfered',
) as unknown as number;
const CLOSED_STATUS_VALUE = t('meter_status_closed') as unknown as number;
const BLOCKED_STATUS_VALUE = t('meter_status_blocked') as unknown as number;
const DISABLED_STATUS_VALUE = t('meter_status_disabled') as unknown as number;

export const METER_STATUS_MAP = {
  [TRANSFER_STATUS_KEY]: TRANSFER_STATUS_VALUE,
  [VERIFICATION_STATUS_KEY]: VERIFICATION_STATUS_VALUE,
  [TRANSFERED_STATUS_KEY]: TRANSFERED_STATUS_VALUE,
  [CLOSED_STATUS_KEY]: CLOSED_STATUS_VALUE,
  [BLOCKED_STATUS_KEY]: BLOCKED_STATUS_VALUE,
  [DISABLED_STATUS_KEY]: DISABLED_STATUS_VALUE,
};

export interface IMeterInfo extends IMeter {
  statusCode: keyof typeof METER_STATUS_MAP;
}
