export default {
  ru: {
    menu_home: 'Главная',
    menu_requests: 'Заявки',
    menu_payment: 'Оплата',
    payment_account: 'Оплата ЛС _ident_',
    payment_all: 'Оплатить все счета',
    menu_meters: 'Показания',
    menu_services: 'Наш сервис',
    menu_polls: 'Опросы',
    menu_bills: 'Квитанции',
    menu_events: 'События',
    meter_status_transfer: 'Пора передать показания',
    meter_status_verification: 'Требуется поверка',
    meter_status_transfered: 'Показания переданы',
    meter_status_closed: 'Период закрыт',
    meter_status_blocked: 'Передача заблокирована',
    meter_status_disabled: 'Отключен',

    main_loading: 'Загрузка...',
    residents_personal_account: 'Личный кабинет жителя',
    payment_history: 'История платежей',
    request_number: 'Заявка № _requestNumber_',
    new_request: 'Новая заявка',
    meters_info: 'Что такое поверка и как мне ее сделать?',
    meter_name: 'Прибор учета _meterName_',
    meters_history: 'История показаний "_meterName_"',
    profile: 'Профиль',
    settings: 'Настройки',
    change_password: 'Изменение пароля',
    events: 'Уведомления',
    news: 'Новости',
    event_detail: 'Уведомление "_eventName_"',
    news_detail: 'Новость "_newsName_"',
    right_management: 'Управление правами',
    add_new_user: 'Новый пользователь',
    personal_info: 'Мои данные',
    invoices: 'Квитанции',
    not_found: 'Страница не найдена',

    emergency_works: {
      completed_text: 'Нет аварийных работ | Аварийные работы по адресу | Аварийные работы по адресам',
      completed_text_2: '{0} с {1} по {2}!',
    },
  },
  en: {
    menu_home: 'Home',
    menu_requests: 'Requests',
    menu_payment: 'Payment',
    payment_account: 'Payment of personal account _ident_',
    payment_all: 'Pay all personal accounts',
    menu_meters: 'Meters',
    menu_services: 'Our services',
    menu_polls: 'Polls',
    menu_bills: 'Bills',
    menu_events: 'Events',
    meter_status_transfer: "It's time to take a reading",
    meter_status_verification: 'Verification required',
    meter_status_transfered: 'Meter readings transmitted',
    meter_status_closed: 'Period closed',
    meter_status_blocked: 'Meter readings blocked',
    meter_status_disabled: 'Disabled',
    
    main_loading: 'Loading...',
    residents_personal_account: "Resident's personal account",
    payment_history: 'Payment history',
    request_number: 'Request #_requestNumber_',
    new_request: 'New application',
    meters_info: 'What is verification and how do I do it?',
    meter_name: 'Meter _meterName_',
    meters_history: 'Testimony history "_meterName_"',
    profile: 'Profile',
    settings: 'Settings',
    change_password: 'Change Password',
    events: 'Events',
    news: 'News',
    event_detail: 'Event "_eventName_"',
    news_detail: 'News "_newsName_"',
    right_management: 'Rights management',
    add_new_user: 'New user',
    personal_info: 'My information',
    invoices: 'Invoices',
    not_found: 'Page not found',

    emergency_works: {
      completed_text: 'No emergency work | Emergency work at | Emergency work at the addresses',
      completed_text_2: '{0} from {1} to {2}'
    },
  },
};
